import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { AddLocation } from "../Utils/Modals";
import { getAccessToken } from "../Utils/Utils.js";
import { Delete } from "@mui/icons-material";
import axios from "axios";

function Locations() {
  const [locations, setLocations] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/get_locations`;
  const { t } = useTranslation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRemoveLocation = (index) => {
    const location = locations[index];
    const url =
        process.env.REACT_APP_SOPHYAPP_API_URL +
        `/de/react/${getAccessToken()}/settings/remove_location`,
      data = {
        location_id: location.id,
      };
    axios.post(url, data).then((response) => {
      if (response.data.status === "success") {
        const newLocations = [...locations];
        newLocations.splice(index, 1);
        setLocations(newLocations);
      }
    });
  };

  useEffect(() => {
    axios.get(url).then((response) => {
      setLocations(response.data);
    });
  }, [locations]);

  return (
    <div>
      <button
        type="submit"
        className="login-button"
        onClick={(e) => {
          setShowLocationModal(true);
        }}
      >
        {t("settings.addLocation")}
      </button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Erstellt am</TableCell>
              {/* <TableCell>Bearbeitet am</TableCell> */}
              <TableCell>Löschen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locations
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((location, index) => (
                <TableRow key={index}>
                  <TableCell>{location.name}</TableCell>
                  <TableCell>{location.created_at.toLocaleString()}</TableCell>
                  {/* <TableCell>
                    {location.modified_at
                      ? location.modified_at.toLocaleString()
                      : "N/A"}
                  </TableCell> */}
                  <TableCell>
                    <IconButton
                      color="error"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this location?"
                          )
                        ) {
                          handleRemoveLocation(index);
                        }
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={locations.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <AddLocation
        show={showLocationModal}
        backdrop="static"
        onHide={() => setShowLocationModal(false)}
        locations={locations}
        setLocations={setLocations}
      ></AddLocation>
    </div>
  );
}

export default Locations;
