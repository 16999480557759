import axios from "axios";

const resizeImage = (file, maxWidth, callback) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const img = document.createElement("img");
    img.onload = () => {
      const scale = maxWidth / img.width;
      const canvas = document.createElement("canvas");
      canvas.width = maxWidth;
      canvas.height = img.height * scale;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(callback, "image/jpeg", 0.95);
    };
    img.src = e.target.result;
  };
  reader.readAsDataURL(file);
};

export const handleImageChange = async (
  file,
  objectId,
  updateProfileImageUrl,
  setImage,
  getAccessToken,
  getImageUrl,
  path = "account_patient_image"
) => {
  if (file) {
    const fileSizeInKB = file.size / 1024;

    if (fileSizeInKB > 1000) {
      alert(
        "Die Datei ist zu groß. Bitte wählen kleinere Datei mit max. 1MB aus."
      );
      return;
    }
    const MAX_WIDTH = 300;
    const MAX_FILE_SIZE = 100000;
    resizeImage(file, MAX_WIDTH, async (blob) => {
      if (blob.size <= MAX_FILE_SIZE) {
        // setImage(URL.createObjectURL(blob));
        const formData = new FormData();
        formData.append("profileImage", blob);
        formData.append("id", objectId);

        const url =
          process.env.REACT_APP_SOPHYAPP_API_URL +
          `/de/react/${getAccessToken()}/upload/upload_${path}`;
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        try {
          const response = await axios.post(url, formData, config);
          const newProfileImageUrl = getImageUrl(response.data.url);
          setImage(process.env.REACT_APP_S3_PREFIX + newProfileImageUrl);
          //   updateProfileImageUrl(newProfileImageUrl);
        } catch (error) {
          alert(
            "Aktuell können nur Bilder zu selbst angelegten Programmen hinzugefügt werden. Bitte überprüfen Sie Ihre Berechtigung!"
          );
          console.error("Error permission...", error);
        }
      } else {
        console.error("File too large after resizing");
      }
    });
  }
};

export const handleRemoveImage = async (
  getAccessToken,
  id,
  setProgramImage,
  defaultImage = "/images/image_placeholder.png",
  type = "program",
  prop
) => {
  // setProgramImage(defaultImage);
  const formData = new FormData();
  formData.append("id", id);
  formData.append("type", type);
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/upload/remove_image`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  try {
    const response = await axios.post(url, formData, config);
    setProgramImage(defaultImage);
    if (type === "program" && prop) {
      //   prop.setProgramImage(defaultImage);
    }
  } catch (error) {
    alert(
      "Aktuell können nur Bilder gelöscht werden, die Sie selbst hochgeladen haben! Bitte überprüfen Sie Ihre Berechtigungen."
    );
    console.error("Error removing image", error);
  }
};
