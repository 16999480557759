import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { getAccessToken } from "../Utils/Utils.js";
import axios from "axios";
import Loader from "../Utils/Loader";
import "./Preferences.css";
import { Modal } from "react-bootstrap";
import PaymentMethod from "./PaymentMethod";

export default function Payment() {
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/payment`;
  const headers = {
    "Content-Type": "multipart-form-data",
    Accept: "application/json",
  };
  const [payment, setPayment] = useState({
    abo: "",
    payment: "",
    count: "",
    max: "",
    begin: "",
  });
  const [request, setRequest] = useState({
    loading: false,
    data: null,
    error: false,
  });
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  let content = null;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleOpen();
  };

  const cancelSubscription = () => {
    if (
      window.confirm("Bist du dir sicher, dass du das Abo kündigen möchtest?")
    ) {
      const urlCancelSubscription =
        process.env.REACT_APP_SOPHYAPP_API_URL +
        `/de/react/${getAccessToken()}/settings/cancel_payment`;
      axios
        .post(urlCancelSubscription, { headers: headers })
        .then((res) => {
          alert("Abo erfolgreich gekündigt - vielen Dank!");
          localStorage.clear();
          window.location.reload();
        })
        .catch((err) => {
          alert("Abo konnte nicht gekündigt werden- vielen Dank!");
        });
    }
  };

  useEffect(() => {
    setRequest({
      loading: true,
      data: null,
      error: false,
    });
    axios
      .get(url, { headers: headers })
      .then((response) => {
        setRequest({
          loading: false,
          data: response.data,
          error: false,
        });

        setPayment({
          abo: response.data.data.license_name,
          payment:
            response.data.data.payment == "treatsoft_management" ||
            response.data.data.payment == "treatsoft management" ||
            response.data.data.payment == "VenDoc" ||
            response.data.data.payment == "Vendoc"
              ? "Rechnung"
              : response.data.data.payment == "Fastspring" ||
                response.data.data.reference != null
              ? "Kreditkarte"
              : "Sonstiges",
          count: response.data.data.programs_count,
          max: response.data.max,
          begin: response.data.begin,
        });
      })
      .catch(() => {
        setRequest({
          loading: false,
          data: null,
          error: true,
        });
      });
  }, [url]);

  if (request.error) {
    content = <div>{t("payment.notFound")}</div>;
  }

  if (request.loading) {
    content = <Loader />;
  }

  if (request.data) {
    content = (
      <Container fluid className="h-100">
        <Row className="h-100 table-row general-settings">
          <div className="general-settings-header">{t("payment.terms")}</div>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("payment.abo")}
            </Col>
            <Col md={5}>
              <b>{payment.abo}</b>
            </Col>
          </Row>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("payment.paymenttype")}
            </Col>
            <Col md={5}>
              <b>{payment.payment}</b>
            </Col>
          </Row>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("payment.programs")}
            </Col>
            <Col md={5}>
              <b>{payment.count}</b> {t("payment.from")} <b>{payment.max}</b>
            </Col>
          </Row>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("payment.begin")}
            </Col>
            <Col md={5}>
              <b>{payment.begin}</b>
            </Col>
          </Row>
          <Row className=" table-row">
            <Col md={3} className="table-column-title">
              <button
                type="submit"
                className="login-button"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                {t("payment.change")}
              </button>
            </Col>
            {!payment.abo.includes("FREE") && (
              <Col md={4} className="table-column-title">
                <button
                  type="button"
                  className="login-button"
                  style={{ backgroundColor: "red", borderColor: "red" }}
                  onClick={cancelSubscription}
                >
                  Abo stornieren
                </button>
              </Col>
            )}
          </Row>
        </Row>
        <Modal show={open} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>sophyapp Abo's</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PaymentMethod handleClose={handleClose} />
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
  return <React.Fragment>{content}</React.Fragment>;
}
