// ExerciseModal.js
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Grid } from "@mui/material";
import { ViewComfy as ViewComfyIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CloseIcon from "@mui/icons-material/Close";
import "../../Assets/stylesheets/ExercisePreviewModal.css";
import ExerciseInputs from "../Utils/ExerciseInputFields";

const ExercisePreviewModal = ({
  show,
  handleClose,
  gridSize,
  toggleGridSize,
  exercises,
  setExercises,
}) => {
  const { t } = useTranslation();
  const [selectedExerciseIndex, setSelectedExerciseIndex] = useState(null);
  const [expandedDescriptionIndex, setExpandedDescriptionIndex] =
    useState(null);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleExerciseClick = (index) => {
    if (selectedExerciseIndex === index) {
      setSelectedExerciseIndex(null);
    } else {
      setSelectedExerciseIndex(index);
    }
  };

  const toggleDescription = (event, index) => {
    event.stopPropagation();
    if (expandedDescriptionIndex === index) {
      setExpandedDescriptionIndex(null);
    } else {
      setExpandedDescriptionIndex(index);
    }
  };

  const handleDeleteExercise = (event, exerciseId, index) => {
    event.stopPropagation();
    setExercises((exercises) => exercises.filter((_, idx) => idx !== index));
  };

  const handleInputChange = (field, value, index) => {
    setExercises((prevExercises) =>
      prevExercises.map((ex, idx) =>
        idx === index ? { ...ex, [field]: value } : ex
      )
    );
  };

  const renderDescriptionAsList = (descriptionText) => {
    let lines;
    if (Array.isArray(descriptionText)) {
      lines = descriptionText;
    } else if (typeof descriptionText === "string") {
      lines = descriptionText.replace(/<br\s*\/?>/gi, "\n").split("\n");
    } else {
      console.error("Invalid type for descriptionText");
      return null;
    }
    return (
      <ul>
        {lines.map((line, index) => (
          <li key={index}>{line}</li>
        ))}
      </ul>
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedExercises = reorder(
      exercises,
      result.source.index,
      result.destination.index
    );
    setExercises(reorderedExercises);
  };

  return (
    <Modal show={show} onHide={handleClose} centered className="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>{t("programs.overview")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button
          variant="outline-primary"
          onClick={toggleGridSize}
          className="toggle-grid-size"
          style={{
            color: "var(--main-color)",
            borderColor: "var(--main-color)",
          }}
        >
          <ViewComfyIcon />
        </Button>
        <span className="reorder-info">{t("exercises.reorderInfo")}</span>
        {exercises.length > 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-exercises">
              {(provided) => (
                <Grid
                  container
                  spacing={2}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {exercises.map((exercise, index) => (
                    <Draggable
                      key={index}
                      draggableId={exercise.id.toString() + index.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <Grid
                          item
                          xs={gridSize === 1 ? 12 : gridSize === 2 ? 6 : 4}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div
                            className={`exercise-card-preview ${
                              gridSize > 1 ? "column-layout" : ""
                            }`}
                            onClick={() => handleExerciseClick(index)}
                          >
                            <CloseIcon
                              className="exercise-card-close"
                              onClick={(e) =>
                                handleDeleteExercise(e, exercise.id, index)
                              }
                            />
                            <span className="exercise-order">
                              {index + 1}.{" "}
                            </span>
                            <span className="exercise-name">
                              {exercise.name}
                            </span>
                            <img
                              src={
                                process.env.REACT_APP_SOPHYAPP_THUMBS_URL +
                                exercise.image
                              }
                              alt={exercise.name}
                              className="exercise-image"
                            />
                            <span
                              className="show-description-preview-text"
                              onClick={(e) => toggleDescription(e, index)}
                            >
                              {expandedDescriptionIndex === index
                                ? t("exercises.hideDescription")
                                : t("exercises.showDescription")}
                            </span>
                            {expandedDescriptionIndex === index && (
                              <div className="exercise-preview-description">
                                {renderDescriptionAsList(exercise.steps)}
                              </div>
                            )}
                          </div>
                          {selectedExerciseIndex === index && (
                            <ExerciseInputs
                              exercise={exercises[index]}
                              handleInputChange={(field, value) =>
                                handleInputChange(field, value, index)
                              }
                            />
                          )}
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div className="no-exercises">{t("exercises.noExercisesAdded")}</div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ExercisePreviewModal;
