import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ProgramTemplateFilters = ({ params, setParams }) => {
  const { t } = useTranslation();

  const handleOnChangeFilter = (event) => {
    setParams({
      ...params,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Row>
        <Col md={12} className="sidebar-filter">
          <select
            name="duration"
            className="select-box sidebar-filter-elem sidebar-filter-elem-template"
            value={params.duration}
            onChange={handleOnChangeFilter}
          >
            <option style={{ display: "none" }} defaultValue>
              {t("programs.duration-select")}
            </option>
            <option value="all">{t("options.goal.all")}</option>
            <option value="1">{t("options.duration.1")}</option>
            <option value="2">{t("options.duration.2")}</option>
            <option value="3">{t("options.duration.3")}</option>
            <option value="4">{t("options.duration.4")}</option>
            <option value="5">{t("options.duration.5")}</option>
            <option value="6">{t("options.duration.6")}</option>
            <option value="7">{t("options.duration.7")}</option>
            <option value="8">{t("options.duration.8")}</option>
          </select>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="sidebar-filter">
          <select
            name="goal"
            className="select-box sidebar-filter-elem sidebar-filter-elem-template"
            value={params.goal}
            onChange={handleOnChangeFilter}
          >
            <option style={{ display: "none" }} defaultValue>
              {t("programs.goal-select")}
            </option>
            <option value="all">{t("options.goal.all")}</option>
            <option value="1">{t("options.goal.schmerzlinderung")}</option>
            <option value="2">{t("options.goal.kraftigung")}</option>
            <option value="3">{t("options.goal.beweglichkeit")}</option>
            <option value="4">{t("options.goal.koordination")}</option>
            <option value="5">{t("options.goal.pravention")}</option>
            <option value="6">{t("options.goal.Entspannung")}</option>
            <option value="7">{t("options.goal.ausdauer")}</option>
          </select>
        </Col>
      </Row>
    </>
  );
};

export default ProgramTemplateFilters;
