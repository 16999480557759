//toDO combine with Program and switch between program/template
//currently only a fork for fast solution
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Programs.css";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Loader from "../Utils/Loader";
import { useAxiosGet } from "../../Hooks/HttpRequests";
import {
  getAccessToken,
  isActive,
  getEndDateOfProgram,
  programInterval,
  getTargetName,
} from "../Utils/Utils.js";
import { ExerciseCard } from "./ExerciseCard";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import axios from "axios";
import parse from "html-react-parser";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useTranslation } from "react-i18next";
import Alert from "react-bootstrap/Alert";

function renderExercises(exercises, id) {
  let content = null;
  let exerciseContent = [];
  for (let i = 0; i < exercises?.length; i++) {
    exerciseContent.push(
      <ExerciseCard exercise={exercises[i]} key={exercises[i].id} />
    );
  }
  return exerciseContent;
}

function getProgramSessions(exercises, id) {
  let sessions = [];
  for (let i = 0; i < exercises?.length; i++) {
    if (exercises[i].id == id) {
      for (let j = 0; j < exercises[i].sessions?.length; j++) {
        {
          sessions.push(exercises[i].sessions[j]);
        }
      }
    }
  }
  return sessions;
}

function setExerciseLayout(data, columns) {
  let content_block = [];
  let content = null;
  for (let i = 0; i < data?.length; i += columns) {
    content = (
      <Row className="program-exercise-row">
        <Col xs={12} sm={6} md={6} lg={6} xxl={3}>
          {data[i]}
        </Col>
        {(() => {
          if (data[i + 1] !== undefined && columns >= 2) {
            return (
              <Col xs={12} sm={6} md={6} lg={6} xxl={3}>
                {data[i + 1]}
              </Col>
            );
          } else {
          }
        })()}
        {(() => {
          if (data[i + 2] !== undefined && columns >= 3) {
            return (
              <Col xs={12} sm={6} md={6} lg={6} xxl={3}>
                {data[i + 2]}
              </Col>
            );
          }
        })()}
        {(() => {
          if (data[i + 3] !== undefined && columns >= 4) {
            return (
              <Col xs={12} sm={6} md={12} lg={6} xxl={3}>
                {data[i + 3]}
              </Col>
            );
          }
        })()}
      </Row>
    );
    content_block.push(content);
  }
  return content_block;
}

function getBootstrapBreakpoint() {
  var w = window.innerWidth;
  return w < 400 ? 1 : w < 900 ? 2 : w < 1440 ? 2 : 4;
}

function sendFeedback(lvl, feedbacktext, id) {
  const url_feedback =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/v2/" +
    getAccessToken() +
    "/program_session.json";
  var params = new FormData();
  params.append("program_session[pain_level]", lvl);
  params.append("program_session[program_id]", id);
  params.append("program_session[program_feedback", feedbacktext);
  axios
    .post(url_feedback, params)
    .then(function (response) {
      //improve via state
      window.location.reload();
    })
    .catch(function (error) {});
}

function workoutDoneForToday(last_session) {
  let today = new Date();

  if (
    last_session != null &&
    last_session.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)
  ) {
    return true;
  }
  return false;
}

function deleteProgram(id) {
  if (
    window.confirm("Sind Sie sicher, dass Sie die Vorlage löschen möchten?")
  ) {
    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      "/de/react/" +
      getAccessToken() +
      "/program/delete_program_template.json";
    var params = new FormData();
    params.append("program_id", id);
    axios
      .post(url, params)
      .then(function (response) {
        window.location.assign("/programs");
      })
      .catch(function (error) {});
  }
}

function ProgramTemplate() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  if (location.showSuccessAlert) {
  }
  let language = "de";
  if (localStorage.getItem("i18nextLng")) {
    language = localStorage.getItem("i18nextLng");
  }
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/${language}/react/${getAccessToken()}/program/get_program_template/${id}`;
  // const url_exercises =
  //   "http://10.101.1.33:8765/de/react/" + getAccessToken() + "/program";

  const [modalShow, setModalShow] = useState(false);
  const [modalProgram, setModalProgramShow] = useState(false);
  const [alertShow, setAlertShow] = useState(true);

  let program = useAxiosGet(url);
  let exercises = useAxiosGet(url);
  let sorted_program_sessions;
  let content = null;

  const handleClick = async (e) => {
    let last_session = sorted_program_sessions[0].created_at;
    let today = new Date();
    if (workoutDoneForToday(last_session)) {
      alert("Maximal eine Session pro Tag erlaubt");
    } else {
      setModalShow(true);
    }
  };

  const editProgram = async (program) => {
    scrollToTop();
    navigate("/programs/create", {
      state: {
        program_data_edit: program,
        program_template_update: true,
      },
    });
  };

  const scrollToTop = async () => {
    const scrollContainer = document.querySelector(".program-overview");
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 0,
      });
    }
  };

  let row_count = 0;

  const { t, i18n } = useTranslation();

  let feedback_text = t("feedback.program-today");
  let disabled = "";

  if (program.error) {
    content = <div>{t("program.programNotFound")}</div>;
  }

  if (program.loading) {
    content = <Loader />;
  }

  if (program.data) {
    let exercise_data = renderExercises(program.data[0].exercises, id);
    let exercise_data_row = setExerciseLayout(
      exercise_data,
      getBootstrapBreakpoint()
    );
    let status_program = isActive(
      program.data.created_at,
      program.data.duration
    );

    let status;
    let variant;
    if (status_program == true) {
      status = "active";
      variant = "success";
    } else {
      status = "inactive";
      variant = "danger";
    }
    let program_sessions = getProgramSessions(program.data[0].exercises, id);
    sorted_program_sessions = program_sessions
      ?.map((obj) => {
        return { ...obj, created_at: new Date(obj.created_at) };
      })
      .sort((a, b) => b.created_at - a.created_at);
    let program_done_button, program_done_button_mobile;
    content = (
      <Container fluid className="h-100">
        <Row className="h-100">
          <Col md={4} lg={3} xl={2} className="program-properties h-md-100">
            <Row>
              <Breadcrumb className="breacrumb">
                <Breadcrumb.Item href="/programs">
                  {" "}
                  &lt; {t("program.templateOverview")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row>
              <Col lg={12} className="program-title-template">
                {parse(program.data[0].name)}
              </Col>
            </Row>
            {/* <Row className={"program-progress-title-" + status}>
              <Col md={12}>
                {t("program.program-progress")}{" "}
                {(
                  (program_sessions.length /
                    (program.data.frequency * program.data.duration)) *
                  100
                ).toFixed(2)}{" "}
                %
              </Col>
            </Row> */}
            {/* <Row className="program-progress-graph justify-content-md-center">
              <ProgressBar
                variant={variant}
                now={
                  (program_sessions.length /
                    (program.data.frequency * program.data.duration)) *
                  100
                }
                style={{ width: "90%" }}
                title={
                  (
                    (program_sessions.length /
                      (program.data.frequency * program.data.duration)) *
                    100
                  ).toFixed(2) + "%"
                }
              />
            </Row> */}
            <Row>
              <Col md={12} className="program-property">
                {t("program.target")}{" "}
                <b>{getTargetName(program.data[0].goal_id)}</b>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="program-property">
                {t("program.frequencyDot")}{" "}
                <b>{programInterval(program.data[0].frequency)}</b>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="program-property">
                {t("program.durationDot")}{" "}
                <b>
                  {program.data[0].duration} {t("program.weeks")}
                </b>
              </Col>
            </Row>
            {/* <Row>
              <Col md={12} className="program-property">
                Ende des Programms:{" "}
                <b>
                  {new Date(
                    getEndDateOfProgram(
                      program.data[0].created_at,
                      program.data[0].duration
                    )
                  ).toLocaleDateString("de-DE")}
                </b>
              </Col>
            </Row> */}
            <Row>
              <Col md={12} className="program-property">
                {t("program.annotation")}
                <b>
                  {program.data[0].description
                    ? program.data[0].description
                    : "Keine Anmerkung"}
                </b>
              </Col>
            </Row>
            <Row className="program-action-buttons">
              <Col>
                <center>
                  <button
                    type="submit"
                    className={"btn-program-property-action"}
                    onClick={() => editProgram(program.data[0])}
                    style={{ cursor: "pointer" }}
                  >
                    {t("program.editTemplate")}
                  </button>
                </center>
              </Col>
            </Row>
            {/* <Row className="program-action-buttons">
                  <Col>
                    <center>
                      <button
                        type="submit"
                        className={"btn-program-property-action"}
                        onClick={() => assignPatient(id, setModalAssignPatient)}
                        style={{ cursor: "pointer" }}
                      >
                        Anderen Patienten zuweisen
                      </button>
                    </center>
                  </Col>
                </Row> */}
            <Row className="program-action-buttons">
              <Col>
                <center>
                  <button
                    type="submit"
                    className={"btn-program-property-action-delete"}
                    onClick={() => deleteProgram(program.data[0].id)}
                    style={{ cursor: "pointer" }}
                  >
                    {t("program.deleteTemplate")}
                  </button>
                </center>
              </Col>
            </Row>
            {program_done_button}
          </Col>
          <Col md={8} lg={9} xl={10} className="exercise-details-wrapper">
            {exercise_data_row}
            {program_done_button_mobile}
          </Col>
        </Row>
      </Container>
    );
  }

  return <div className="h-100">{content}</div>;
}

export default ProgramTemplate;
