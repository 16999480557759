import Spinner from "react-bootstrap/Spinner";
import "./Utils.css";

function SpinningLoader(props) {
  return (
    <div className="d-flex justify-content-center">
      <Spinner
        animation="grow"
        role="status"
        style={{
          width: "20rem",
          height: "20rem",
          color: "var(--main-color)",
          top: "30%",
        }}
        className="spinner"
      >
        <span className="visually-hidden"></span>
      </Spinner>
    </div>
  );
}

export default SpinningLoader;
