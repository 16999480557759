import React, { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Preferences.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AccountContext } from "../Contexts/Context";

export default function Sidebar(props) {
  let content = null;
  const { t, i18n } = useTranslation();
  const context = useContext(AccountContext);
  const [mobileShowSettingsList, setMobileShowSettingsList] = useState(true);
  const [mobileShowSettingsDetails, setMobileShowSettingsDetails] =
    useState(false);
  const isMobile = useMediaQuery("(max-width:900px)");
  const smallMobile = useMediaQuery("(max-width:500px");
  const backlink = (
    <div
      onClick={() => (
        setMobileShowSettingsList(true), setMobileShowSettingsDetails(false)
      )}
    >
      {t("settings.backlink")}
    </div>
  );

  var mobileHeaderDetails = (
    <Row>
      <Col md={12} className="sidebar-header">
        {backlink}
      </Col>
    </Row>
  );
  var mainContent = (
    <Col lg={10} xxl={10} xxxl={11} className="sb-main-content h-100">
      {isMobile ? mobileHeaderDetails : null}
      <Row>
        <Col md={12} className="sb-main-content-header">
          {props.title}
        </Col>
      </Row>
      <Row>{props.contentdisplay}</Row>
    </Col>
  );

  content = (
    <Container fluid className="h-100 sidebar-menu">
      <Row className="h-100">
        <Col
          xxs={12}
          lg={2}
          xxl={2}
          xxxl={1}
          className="sidebar-properties h-100"
        >
          <Row>
            <Col md={12} className="sidebar-header">
              {(isMobile && mobileShowSettingsList) || !isMobile
                ? t("settings.settings")
                : backlink}
            </Col>
          </Row>
          <NavLink to="/preferences/tags" activeclassname="subactive">
            <Row className="program-progress-title sub-menu-item">
              <Col
                xxs={12}
                xs={12}
                lg={12}
                className="preference-row-mobile"
                onClick={
                  isMobile
                    ? () => {
                        setMobileShowSettingsList(false);
                        setMobileShowSettingsDetails(true);
                      }
                    : null
                }
              >
                {t("settings.tags")}
              </Col>
            </Row>
          </NavLink>
          <NavLink to="/preferences/favorites" activeclassname="subactive">
            <Row className="program-progress-title sub-menu-item">
              <Col
                xxs={12}
                xs={12}
                lg={12}
                className="preference-row-mobile"
                onClick={
                  isMobile
                    ? () => {
                        setMobileShowSettingsList(false);
                        setMobileShowSettingsDetails(true);
                      }
                    : null
                }
              >
                {t("settings.favorites")}
              </Col>
            </Row>
          </NavLink>
          {/* {context.userData.client_id != null &&
          context.userData.client_id != 0 ? ( */}
          <NavLink to="/preferences/users" activeclassname="subactive">
            <Row className="program-progress-title sub-menu-item">
              <Col
                xxs={12}
                xs={12}
                lg={12}
                className="preference-row-mobile"
                onClick={
                  isMobile
                    ? () => {
                        setMobileShowSettingsList(false);
                        setMobileShowSettingsDetails(true);
                      }
                    : null
                }
              >
                {t("settings.user")}
              </Col>
            </Row>
          </NavLink>
          {/* ) : null} */}
          {context.userData.client_id != null &&
          context.userData.client_id != 0 ? (
            <NavLink to="/permissions/groups" activeclassname="subactive">
              <Row className="program-progress-title sub-menu-item">
                <Col
                  xxs={12}
                  xs={12}
                  lg={12}
                  className="preference-row-mobile"
                  onClick={
                    isMobile
                      ? () => {
                          setMobileShowSettingsList(false);
                          setMobileShowSettingsDetails(true);
                        }
                      : null
                  }
                >
                  {t("groups.groups")}
                </Col>
              </Row>
            </NavLink>
          ) : null}
          {context.userData.client_id != null &&
          context.userData.client_id != 0 ? (
            <NavLink to="/preferences/locations" activeclassname="subactive">
              <Row className="program-progress-title sub-menu-item">
                <Col
                  xxs={12}
                  xs={12}
                  lg={12}
                  className="preference-row-mobile"
                  onClick={
                    isMobile
                      ? () => {
                          setMobileShowSettingsList(false);
                          setMobileShowSettingsDetails(true);
                        }
                      : null
                  }
                >
                  {t("settings.locations")}
                </Col>
              </Row>
            </NavLink>
          ) : null}
          {/* <NavLink to="/preferences/analysis" activeclassname="subactive">
            <Row className="program-progress-title sub-menu-item">
              <Col
                xxs={12}
                xs={12}
                lg={12}
                className="preference-row-mobile"
                onClick={
                  isMobile
                    ? () => {
                        setMobileShowSettingsList(false);
                        setMobileShowSettingsDetails(true);
                      }
                    : null
                }
              >
                {t("settings.analysis")}
              </Col>
            </Row>
          </NavLink> */}
          <NavLink to="/preferences/general" activeclassname="subactive">
            <Row className="program-progress-title sub-menu-item">
              <Col
                xxs={12}
                xs={12}
                lg={12}
                className="preference-row-mobile"
                onClick={
                  isMobile
                    ? () => {
                        setMobileShowSettingsList(false);
                        setMobileShowSettingsDetails(true);
                      }
                    : null
                }
              >
                {t("settings.general")}
              </Col>
            </Row>
          </NavLink>
          <NavLink to="/preferences/profile" activeclassname="subactive">
            <Row className="program-progress-title sub-menu-item">
              <Col
                xs={12}
                lg={12}
                className="preference-row-mobile"
                onClick={
                  isMobile
                    ? () => {
                        setMobileShowSettingsList(false);
                        setMobileShowSettingsDetails(true);
                      }
                    : null
                }
              >
                {t("settings.profile")}
              </Col>
            </Row>
          </NavLink>
          <NavLink to="/preferences/payment" activeclassname="subactive">
            <Row>
              <Col
                xxs={12}
                xs={12}
                lg={12}
                className="preference-row-mobile sub-menu-item"
                onClick={
                  isMobile
                    ? () => {
                        setMobileShowSettingsList(false);
                        setMobileShowSettingsDetails(true);
                      }
                    : null
                }
              >
                {t("settings.payments")}
              </Col>
            </Row>
          </NavLink>
          <NavLink to="/preferences/mailarchive" activeclassname="subactive">
            <Row>
              <Col
                xxs={12}
                xs={12}
                lg={12}
                className="preference-row-mobile sub-menu-item"
                onClick={
                  isMobile
                    ? () => {
                        setMobileShowSettingsList(false);
                        setMobileShowSettingsDetails(true);
                      }
                    : null
                }
              >
                {t("settings.mail")}
              </Col>
            </Row>
          </NavLink>
          <NavLink to="/preferences/version" activeclassname="subactive">
            <Row>
              <Col
                xxs={12}
                xs={12}
                lg={12}
                className="preference-row-mobile sub-menu-item"
                onClick={
                  isMobile
                    ? () => {
                        setMobileShowSettingsList(false);
                        setMobileShowSettingsDetails(true);
                      }
                    : null
                }
              >
                {t("settings.version")}
              </Col>
            </Row>
          </NavLink>
          {/* <NavLink to="/preferences/export" activeclassname="subactive">
            <Row>
              <Col
                xxs={12}
                xs={12}
                lg={12}
                className="preference-row-mobile sub-menu-item"
                onClick={
                  isMobile
                    ? () => {
                        setMobileShowSettingsList(false);
                        setMobileShowSettingsDetails(true);
                      }
                    : null
                }
              >
                {t("settings.data-export")}
              </Col>
            </Row>
          </NavLink> */}
        </Col>
        {isMobile ? null : mainContent}
      </Row>
    </Container>
  );
  return (
    <div className="h-100">
      {" "}
      {isMobile ? (mobileShowSettingsList ? content : mainContent) : content}
    </div>
  );
}
