const brandingSettings = {
  default: {
    css: "./Styles/variables.css",
    name: "sophyapp",
    imagePath: "/images/sa_logo_solo_4c_pos.svg",
    title: "Therapeut sophyapp",
    icon: "/favicon.ico",
  },
  "medfit.ch": {
    css: "./Styles/medfit.css",
    name: "medfit",
    imagePath: "/images/ci/medfit/medfit1.jpg",
    title: "Langfristig therapieren!",
    icon: "/images/ci/medfit/faviconV2.ico",
  },
  "therapeut.medaix-exercise.de": {
    css: "./Styles/medaix.css",
    name: "medaix",
    imagePath: "/images/ci/medaix/medaix_logo.png",
    title: "Therapeut MedAix",
    icon: "/images/ci/medaix/favicon.ico",
  },
  "therapiecentral.at": {
    css: "./Styles/therapiecentral.css",
    name: "therapiecentral",
    imagePath: "/images/ci/therapie_central/TherapieCentral.jpg",
    title: "Therapie Central",
    icon: "/images/ci/therapie_central/favicon.ico",
  },
};

export function getBrandingSettings(hostname) {
  const key = Object.keys(brandingSettings).find((key) =>
    hostname.endsWith(key)
  );
  return brandingSettings[key] || brandingSettings.default;
}
