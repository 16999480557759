import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import "../Preferences/Preferences.css";
import axios from "axios";
import { getAccessToken } from "../Utils/Utils.js";

export default function User() {
  const [user, setUser] = useState();
  const [groups, setGroups] = useState();
  const { t } = useTranslation();
  let content = null;
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/general`;

  const handleSubmit = (e) => {
    var params = new FormData();
    params.append("", "test");
    axios
      .post(url, params)
      .then(function (response) {})
      .catch(function (error) {});
    e.preventDefault();
  };
  const headers = {
    "Content-Type": "multipart-form-data",
    Accept: "application/json",
  };
  useEffect(() => {
    axios
      .get(url, { headers: headers })
      .then((response) => {
        setUser(response.data.user);
        setGroups(response.data.groups);
      })
      .catch(() => {});
  }, [url]);

  content = (
    <Container fluid className="h-100 wrapper-mobile">
      <Row className="h-100 table-row general-settings">
        <div className="general-settings-header">{t("users.management")}</div>
        <Row className="h-100 table-row">
          <Col md={3} className="table-column-title">
            {t("profile.email")}
          </Col>
          <Col md={5}>
            <input
              name="ex_weight"
              type="checkbox"
              checked={user.ex_weight}
              className="profile-input-field"
              disabled
            />
          </Col>
        </Row>
        <Row className="h-100 table-row">
          <Col md={3} className="table-column-title">
            {t("profile.firstname")}
          </Col>
          <Col md={5}>
            <select
              type="text"
              name="weight_unit"
              className="profile-input-field"
              value={user.weight_unit}
              disabled
            >
              <option value="kg">kg</option>
              <option value="lbs">lbs</option>
            </select>
          </Col>
        </Row>
        <Row className="h-100 table-row">
          <Col md={3} className="table-column-title">
            {t("profile.lastname")}
          </Col>
          <Col md={5}>
            <input
              type="checkbox"
              name="pre_sexuality"
              className="profile-input-field"
              checked={user.pre_sexuality}
              disabled
            />
          </Col>
        </Row>
        <Row className="h-100 table-row">
          <Col md={3} className="table-column-title">
            {t("client.groupss")}
          </Col>
          {/* Transferlist mit Gruppen */}
          <Col md={5}>
            <select
              type="text"
              name="show_all_exercises"
              className="profile-input-field"
              value={user.show_all_exercises}
              disabled
            >
              <option value="1">{t("profile.all")}</option>
              <option value="0">{t("profile.own")}</option>
            </select>
          </Col>
        </Row>
      </Row>
      <Row className="h-100 table-row">
        <Col md={3} className="table-column-title">
          <button
            type="submit"
            className="login-button"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            {t("profile.save")}
          </button>
        </Col>
      </Row>
    </Container>
  );

  return <React.Fragment>{content}</React.Fragment>;
}
