import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import "../Preferences/Preferences.css";
import "../Programs/Programs.css";
import { getAccessToken } from "../Utils/Utils.js";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AddGroupToClient } from "../Utils/Modals";
import { Link } from "react-router-dom";

export default function Groups() {
  const { t } = useTranslation();
  const [groups, setGroups] = useState();
  const [showAddGroup, setShowAddGroup] = useState();
  var content;

  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/get_permission_groups`;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  useEffect(() => {
    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => {
        setGroups(response.data.groups);
      })
      .catch(() => {
        alert("something went wrong");
      });
  }, [url]);

  content = (
    <>
      {" "}
      <button
        type="submit"
        className="login-button"
        onClick={(e) => {
          setShowAddGroup(true);
        }}
      >
        {t("client.addgroup")}
      </button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("groups.name")}</TableCell>
            <TableCell>{t("groups.createdAt")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groups &&
            groups?.map((group) => {
              return (
                <TableRow>
                  <TableCell>
                    <Link to={`/permissions/permissions/${group.id}`}>
                      {group.name}
                    </Link>
                  </TableCell>
                  <TableCell>{group.created_at}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <AddGroupToClient
        show={showAddGroup}
        backdrop="static"
        onHide={() => setShowAddGroup(false)}
        groups={groups}
        setGroup={(groups) => setGroups(groups)}
        // client={clientData.data.client.id}
        // setAlertStatusDanger={() => setAlertStatus("danger")}
        // setAlertStatusSuccess={() => setAlertStatus("success")}
        // alertStatus={alertStatus}
      ></AddGroupToClient>
    </>
  );

  return (
    <Container fluid className="">
      <Row>
        <Col md={10}>{content}</Col>
      </Row>
    </Container>
  );
}
