import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

const ProgramPreviewName = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const {
    programDetailsValidation,
    handleOnChangeProgramDetails,
    programName,
  } = props;

  const handleInputChange = (e) => {
    handleOnChangeProgramDetails(e.target.name, e.target.value);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      setIsEditing(false);
    }
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  return (
    <Row className="program-preview-name">
      <Col md={12} className="sidebar-filter">
        {isEditing ? (
          <input
            type="text"
            name="name"
            className={`program-sidebar-input d-none d-sm-block ${
              programDetailsValidation.name == true ? "is-invalid" : ""
            }`}
            placeholder="Programmname"
            required
            maxLength={35}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyPress={handleInputKeyPress}
            value={programName}
            autoFocus
          />
        ) : (
          <div
            className="program-preview-name-text"
            onClick={handleClick}
            onMouseOver={() => (document.body.style.cursor = "pointer")}
            onMouseOut={() => (document.body.style.cursor = "default")}
          >
            Name: {programName}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default ProgramPreviewName;
