import Alert from "react-bootstrap/Alert";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Alerts(props) {
  return (
    <Alert
      variant={props.variant}
      className="alert-fixed"
      show={props.showAlert}
      onClose={() => props.setShowAlert(false)}
      dismissible
    >
      <Alert.Heading>{props.header}</Alert.Heading>
      <p>{props.main}</p>
    </Alert>
  );
}
