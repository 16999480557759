import React, { useState, useEffect, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import "./Preferences.css";
import "../Programs/Programs.css";
import parse from "html-react-parser";
import { getAccessToken, getSorting, stableSort } from "../Utils/Utils.js";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Loader from "../Utils/Loader";
import {
  AddUserToClient,
  AddUserToGroup,
  PasswordResetPopup,
} from "../Utils/Modals";
import Alert from "react-bootstrap/Alert";
import Groups2Icon from "@mui/icons-material/Groups2";
import DeleteIcon from "@mui/icons-material/Delete";
import { AccountContext } from "../Contexts/Context";

export default function Users() {
  const { t, i18n } = useTranslation();
  const [users, setUsers] = useState();
  const [clientData, setClientData] = useState({
    loading: false,
    data: null,
    error: false,
  });
  const [groups, setGroups] = useState();
  const [sortedData, setSortedData] = useState();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("email_to");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showAddUser, setShowAddUser] = useState();
  const [showAddGroup, setShowAddGroup] = useState();
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState();
  const [alertHeader, setAlertHeader] = useState();
  const [alertBody, setAlertBody] = useState();
  const [initialized, setInitialized] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    user: null,
    group: null,
  });
  const { userData } = useContext(AccountContext);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [currentUserForReset, setCurrentUserForReset] = useState(null);
  var content;

  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/get_client_data`;
  const url_groups =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/get_user_groups`;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const handlePasswordResetClick = (user) => {
    setCurrentUserForReset(user);
    setShowPasswordReset(true);
  };

  function handleRequestSort(event, property) {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleCurrentUser(user) {
    const currentGroup = groups.user_groups.find(
      (group) => group.user2client_id === user.u2c_id
    );
    setCurrentUser({
      user: user,
      group: currentGroup.group_id,
    });
  }

  function removeUser(user) {
    if (
      window.confirm(
        "Bist du dir sicher, dass du diesen Benutzer von deiner Praxis entfernen möchtest?"
      )
    ) {
      var url_remove =
        process.env.REACT_APP_SOPHYAPP_API_URL +
        `/de/react/${getAccessToken()}/clients/remove_user_from_client`;
      var params = new FormData();
      params.append("client_id", clientData.data.client.id);
      params.append("user_id", user.account_id);

      axios
        .post(url_remove, params)
        .then((response) => {
          alert("success");
          // Refresh the page
          window.location.reload();
        })
        .catch((error) => {
          alert("error");
        });
    }
  }

  function registerClient() {
    if (
      userData.subscription.license_name == "FREE" ||
      userData.subscription.max_users == 1
    ) {
      alert(
        "Ihre Lizenz ist nicht ausreichend. Bitte wenden Sie sich direkt per Mail an hello@sophyapp.com um genauere Informationen zu erhalten. Falls Sie gerade ein Abo abgeschlossen haben, melden Sie sich ab und erneut an."
      );
    } else {
      var registerUrl =
        process.env.REACT_APP_SOPHYAPP_API_URL +
        `/de/react/${getAccessToken()}/clients/create_client`;
      axios
        .post(registerUrl, {
          headers: headers,
        })
        .then((response) => {
          alert("Client erfolgreich registriert");
        })
        .catch(() => {
          alert("Client konnte nicht registriert werden");
        });
    }
  }

  useEffect(() => {
    if (initialized) {
      setShowAddGroup(true);
    } else {
      setInitialized(true);
    }
  }, [currentUser]);

  useEffect(() => {
    setClientData({
      loading: true,
      data: null,
      error: false,
    });
    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => {
        setClientData({
          loading: false,
          data: response.data,
          error: false,
        });
        setSortedData(clientData.data);
      })
      .catch(() => {
        setClientData({
          loading: false,
          data: null,
          error: true,
        });
      });
    axios
      .get(url_groups, {
        headers: headers,
      })
      .then((response) => {
        setGroups(response.data);
      })
      .catch(() => {});
  }, [url]);

  useEffect(() => {
    if (clientData.data && clientData.data.users) {
      setSortedData(
        stableSort(clientData.data.users, getSorting(order, orderBy))
      );
    }
  }, [order, orderBy, clientData.data]);

  useEffect(() => {
    if (alertStatus == "success") {
      setShowAlert(true);
      setAlertVariant("success");
      setAlertHeader("Erfolgreich");
      setAlertBody("Einladung erfolgreich versendet");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
    if (alertStatus == "danger") {
      setShowAlert(true);
      setAlertVariant("danger");
      setAlertHeader("Etwas ist schiefgelaufen");
      setAlertBody(
        "Einladung nicht versendet. Benutzerlimit erreicht oder Benutzer bereits eingeladen."
      );
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
    setAlertStatus("");
  }, [alertStatus]);

  if (clientData.error) {
    content = <div>Keine Daten verfügbar</div>;
  }

  if (clientData.loading) {
    content = <Loader />;
  }

  if (clientData.loading == false && clientData.data) {
    if (clientData.data.client && clientData.data.main == null) {
      content = (
        <div>
          <div className="client-preview">{t("settings.client-user")}</div>
          <div className="client-preview-clinic-name">
            {clientData.data.client.center_name}
          </div>
        </div>
      );
    } else if (clientData.data.main) {
      content = (
        <div>
          <button
            type="submit"
            className="login-button"
            onClick={(e) => {
              setShowAddUser(true);
            }}
          >
            {t("client.adduser")}
          </button>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "email"}
                    direction={order}
                    onClick={(event) => handleRequestSort(event, "email")}
                  >
                    {t("client.usermail")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "first_name"}
                    direction={order}
                    onClick={(event) => handleRequestSort(event, "first_name")}
                  >
                    {t("client.userfirstname")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "last_name"}
                    direction={order}
                    onClick={(event) => handleRequestSort(event, "last_name")}
                  >
                    {t("client.userlastname")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>{t("client.groups")}</TableCell>
                <TableCell>Entfernen</TableCell>
                {clientData.data.client.id === 46 && (
                  <TableCell> Passwort zurücksetzen</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData &&
                sortedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => {
                    return (
                      <TableRow key={user.id}>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.first_name}</TableCell>
                        <TableCell>{user.last_name}</TableCell>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleCurrentUser(user);
                          }}
                        >
                          <Groups2Icon sx={{ color: "var(--main-color)" }} />
                        </TableCell>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            removeUser(user);
                          }}
                        >
                          <DeleteIcon sx={{ color: "red" }} />
                        </TableCell>
                        {clientData.data.client.id === 46 && (
                          <TableCell>
                            <button
                              type="button"
                              className="login-button"
                              onClick={() => handlePasswordResetClick(user)}
                            >
                              PW ändern
                            </button>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={clientData.data.users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <AddUserToClient
            show={showAddUser}
            backdrop="static"
            onHide={() => setShowAddUser(false)}
            client={clientData.data.client.id}
            setAlertStatusDanger={() => setAlertStatus("danger")}
            setAlertStatusSuccess={() => setAlertStatus("success")}
            alertStatus={alertStatus}
          ></AddUserToClient>
          <AddUserToGroup
            show={showAddGroup}
            backdrop="static"
            onHide={() => setShowAddGroup(false)}
            options={groups}
            selected={currentUser}
            setAlertStatusDanger={() => setAlertStatus("danger")}
            setAlertStatusSuccess={() => setAlertStatus("success")}
          ></AddUserToGroup>
          <PasswordResetPopup
            show={showPasswordReset}
            user={currentUserForReset}
            onHide={() => setShowPasswordReset(false)}
          />
          <Alert
            variant={alertVariant}
            show={showAlert}
            className="alert-fixed"
            onClose={() => setShowAlert(false)}
            dismissible
          >
            <Alert.Heading>{alertHeader}</Alert.Heading>
            <p>{alertBody}</p>
          </Alert>
        </div>
      );
    } else if (
      clientData.data == null ||
      (clientData.data.message ? true : false)
    ) {
      content = (
        <div className="client-preview">
          {parse(t("settings.client-content"))}
          <button
            type="submit"
            className="btn-new-program"
            onClick={() => registerClient()}
            style={{ cursor: "pointer" }}
          >
            {t("others.register")}
          </button>
        </div>
      );
    } else {
      content = <div>{t("others.onlyMainUser")}</div>;
    }
  }
  return (
    <Container fluid className="">
      <Row>
        <Col md={10}>{content}</Col>
      </Row>
    </Container>
  );
}
