import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import "./Preferences.css";
import Loader from "../Utils/Loader";
import { getAccessToken, getSorting, stableSort } from "../Utils/Utils.js";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

export default function MailArchive() {
  const [mailArchive, setMailArchive] = useState({
    loading: false,
    data: null,
    error: false,
  });
  const [sortedData, setSortedData] = useState();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("email_to");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t } = useTranslation();
  const dateOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/get_mail_archive`;
  let content = null;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  function handleRequestSort(event, property) {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  useEffect(() => {
    setMailArchive({
      loading: true,
      data: null,
      error: false,
    });
    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => {
        setMailArchive({
          loading: false,
          data: response.data,
          error: false,
        });
        setSortedData(mailArchive.data);
      })
      .catch(() => {
        setMailArchive({
          loading: false,
          data: null,
          error: true,
        });
      });
  }, [url]);

  useEffect(() => {
    if (mailArchive.data) {
      setSortedData(stableSort(mailArchive.data, getSorting(order, orderBy)));
    }
  }, [order, orderBy, mailArchive.data]);

  if (mailArchive.error) {
    content = <div>{t("settings.mailArchiveNotFound")}</div>;
  }

  if (mailArchive.loading) {
    content = <Loader />;
  }

  if (mailArchive.data && sortedData) {
    content = (
      <div>
        <Table className="mail-archive-table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "email_to"}
                  direction={order}
                  onClick={(event) => handleRequestSort(event, "email_to")}
                >
                  {t("mails.to")}
                </TableSortLabel>
              </TableCell>
              <TableCell>{t("mails.subject")}</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "send_at"}
                  direction={order}
                  onClick={(event) => handleRequestSort(event, "send_at")}
                >
                  {t("mails.date")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "last_event"}
                  direction={order}
                  onClick={(event) => handleRequestSort(event, "last_event")}
                >
                  {t("mails.action")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "created_at"}
                  direction={order}
                  onClick={(event) => handleRequestSort(event, "created_at")}
                >
                  {t("mails.created")}
                </TableSortLabel>
              </TableCell>
              <TableCell>{t("mails.error")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((mail) => {
                return (
                  <TableRow key={mail.id}>
                    <TableCell>{mail.email_to}</TableCell>
                    <TableCell>{mail.subject}</TableCell>
                    <TableCell>
                      {new Date(mail.send_at).toLocaleDateString(
                        "de-DE",
                        dateOptions
                      )}
                    </TableCell>
                    <TableCell>{mail.last_event}</TableCell>
                    <TableCell>
                      {new Date(mail.created_at).toLocaleDateString(
                        "de-DE",
                        dateOptions
                      )}
                    </TableCell>
                    <TableCell>{mail.error}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={mailArchive.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    );
  }
  return (
    <Container fluid className="about-sop">
      <Row>
        <Col md={10}>{content}</Col>
      </Row>
    </Container>
  );
}
