import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import "./Preferences.css";
import axios from "axios";
import { getAccessToken } from "../Utils/Utils.js";
import Loader from "../Utils/Loader";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CatalogExerciseCard } from "../Programs/ExerciseCard";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Tags() {
  const { t, i18n } = useTranslation();
  const [tags, setTags] = useState({
    loading: true,
    data: null,
    error: false,
  });
  var content;
  const large = useMediaQuery("(min-width:900px)");
  const xlarge = useMediaQuery("(min-width:1400px");

  function chunk(arr, name, size) {
    const chunked_arr = [];
    let index = 0;
    const filteredArr = arr.filter(
      (item) => item.hashtag.hashtag === name.hashtag
    );
    while (index < filteredArr.length) {
      chunked_arr.push(filteredArr.slice(index, size + index));
      index += size;
    }

    return chunked_arr;
  }

  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/get_tags`;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  useEffect(() => {
    setTags({
      loading: true,
      data: null,
      error: false,
    });
    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => {
        setTags({
          loading: false,
          data: response.data,
          error: false,
        });
      })
      .catch(() => {
        setTags({
          loading: false,
          data: null,
          error: true,
        });
      });
  }, [url]);

  if (tags.error) {
    content = <div>{t('others.dataNotFound')}</div>;
  }

  if (tags.loading) {
    content = <Loader />;
  }

  if (tags.data) {
    content = (
      <Row>
        <Col xs={12} lg={10}>
          {tags.data.names.map((tagname) => {
            let chunks;

            if (xlarge) {
              chunks = chunk(tags.data.tags, tagname, 3);
            } else if (large) {
              chunks = chunk(tags.data.tags, tagname, 2);
            } else chunks = chunk(tags.data.tags, tagname, 1);

            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: "var(--color-graph-1)",
                  }}
                >
                  {"#" + tagname.hashtag}
                </AccordionSummary>
                {chunks.map((chunk, index) => (
                  <AccordionDetails>
                    <Row key={index}>
                      {chunk.map((source) => (
                        <Col xs={12} lg={6} xl={4} key={source.id}>
                          <CatalogExerciseCard
                            exercise={source}
                            key={source.id}
                          />
                        </Col>
                      ))}
                    </Row>
                  </AccordionDetails>
                ))}
              </Accordion>
            );
          })}
        </Col>
      </Row>
    );
  }

  return (
    <Container fluid className="about-sop">
      {content}
    </Container>
  );
}
