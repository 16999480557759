import React, { useState, useEffect } from "react";
import { Tab, Tabs, Button } from "react-bootstrap";
import { Select, MenuItem, FormControl, InputLabel, Box } from "@mui/material";
import { TableView } from "../../Components/Analysis/TableView";
// import { fetchData } from "../../Api/api";
import axios from "axios";
import { getAccessToken } from "../../Components/Utils/Utils.js";
// import { CSVLink } from "react-csv";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

const Analysis = () => {
  const [key, setKey] = useState("table"); // For tab switching
  const [filter, setFilter] = useState({
    month: currentMonth.toString(),
    year: currentYear.toString(),
  });
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "year" && value === "all") {
      setFilter({ ...filter, year: value, month: "all" });
    }
    else if (name === "month" && filter.year === "all" && value !== "all") {
      alert("Es kann kein spezifisches Monat gewählt werden, wenn der Jahresfilter auf 'alle' gesetzt ist. Bitte wählen Sie ein spezifisches Jahr aus.");
    } else {
      setFilter((prev) => ({ ...prev, [name]: value }));
    }
  };
  

  const loadData = async () => {
    // try {
    //   const response = await fetchData("base-data");
    //   setData(response.data);
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // }
    setIsLoading(true);
    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      "/de/react/" +
      getAccessToken() +
      "/analysis/index" +
      "?year=" +
      filter.year +
      "&month=" +
      filter.month;
    try {
      const response = await axios.get(url, { headers });
      // Assuming the API response format is directly usable
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // setIsLoading(true);
    loadData();
  }, [filter]);

  // Data processing based on filters to be implemented here

  const yearOptions = Array.from({ length: 5 }, (_, i) =>
    (2020 + i).toString()
  );
  yearOptions.unshift("all");
  const monthOptions = Array.from({ length: 12 }, (_, i) => (i + 1).toString());
  // const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  // const monthOptions = monthNames.map((name, i) => name);
  monthOptions.unshift("all");

  return (
    <div>
      <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
        <Tab eventKey="table" title="Table"></Tab>
      </Tabs>

      <Box
        sx={{ display: "flex", flexDirection: "row", gap: 2, marginLeft: 2 }}
      >
        <FormControl variant="outlined" margin="normal">
          <InputLabel>Year</InputLabel>
          <Select
            name="year"
            value={filter.year}
            onChange={handleFilterChange}
            label="Year"
          >
            {yearOptions.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" margin="normal">
          <InputLabel>Month</InputLabel>
          <Select
            name="month"
            value={filter.month}
            onChange={handleFilterChange}
            label="Month"
          >
            {monthOptions.map((month) => (
              <MenuItem key={month} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {isLoading ? (
        <div>Daten werden geladen...</div>
      ) : (
        <div>{key === "table" && <TableView data={[data.analysis]} />}</div>
      )}
    </div>
  );
};

export default Analysis;
