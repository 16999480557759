import React from "react";
import "./Patients.css";

function ExerciseCard(props) {
  const name = props.patient?.name?.split(" ");
  let classStatus = "patient-preview";
  if (props.active == props.index) {
    classStatus = "patient-preview-active";
  }

  function getProfileImage() {
    if (props.patient?.profile_image_url) {
      return process.env.REACT_APP_S3_PREFIX + props.patient?.profile_image_url;
    } else {
      return "/images/profile_icon.svg";
    }
  }

  return (
    <div
      className={`${classStatus} patient-container`}
      onClick={() => {
        props.setCurrentPatient(props.index);
        if (typeof props.setDetails === "function") {
          props.setDetails();
          props.setList();
        }
      }}
    >
      <img
        className="patient-preview-icon"
        src={getProfileImage()}
        alt="profile-icon"
      />
      <span className="patient-name">
        {name && (
          <>
            {name[0]} <b>{name.slice(1).join(" ")}</b>
          </>
        )}
      </span>
      {/* {props.patient.first_name} <b>{props.patient.last_name}</b> */}
    </div>
  );
}

export default ExerciseCard;
