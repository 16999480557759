import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./Programs.css";
import { useTranslation } from "react-i18next";
import {
  getAccessToken,
  isActive,
  getEndDateOfProgram,
  programInterval,
  getTargetName,
} from "../Utils/Utils.js";
import { AssignPatientModal } from "../Utils/Modals";
import { useAxiosGet } from "../../Hooks/HttpRequests";
import Alert from "react-bootstrap/Alert";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import axios from "axios";
import { handleImageChange, handleRemoveImage } from "../Services/imageUtils";

function assignPatient(id, setModal, fetchPatients) {
  fetchPatients();
  setModal(true);
}

function ProgramCard(props) {
  const { t, i18n } = useTranslation();
  const [modalAssignPatient, setModalAssignPatient] = useState(false);
  const [assignedFailed, setAssignedFailed] = useState(false);
  const [assignedSuccess, setAssignedSuccess] = useState(false);
  const navigate = useNavigate();
  // const url_patients =
  //   process.env.REACT_APP_SOPHYAPP_API_URL +
  //   `/de/react/${getAccessToken()}/patient/get_account_patients`;
  const urlTogglePrivacy =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/program/set_template_privacy`;
  const [isPublic, setIsPublic] = useState(!!props.program.client_id);
  const DEFAULT_PROGRAM_IMAGE_PATH = "/images/image_placeholder.png";
  // let patients = useAxiosGet(url_patients);

  let content = null;
  useEffect(() => {
    if (assignedSuccess) {
      setTimeout(() => {
        setAssignedSuccess(false);
      }, 3000);
    }
  }, [assignedSuccess]);

  const onImageChange = async (event, path) => {
    const file = event.target.files[0];
    if (file) {
      await handleImageChange(
        file,
        props.program.id,
        "placeholder", // props.updateProfileImageUrl,
        setProgramImage,
        getAccessToken,
        getProgramImageUrl,
        path
      );
      event.target.value = "";
    }
  };

  const getProgramImageUrl = (newUrl = null) => {
    if (newUrl) {
      return newUrl;
    } else if (props.program?.program_image_url) {
      if (/^https?:\/\//i.test(props.program.program_image_url)) {
        return props.program.program_image_url;
      }
      return process.env.REACT_APP_S3_PREFIX + props.program.program_image_url;
    }
    return DEFAULT_PROGRAM_IMAGE_PATH;
  };

  const [programImage, setProgramImage] = useState(getProgramImageUrl());
  const [showProgramRemoveButton, setShowProgramRemoveButton] = useState(
    programImage !== DEFAULT_PROGRAM_IMAGE_PATH
  );

  const onProgramImageChange = (newUrl) => {
    if (
      props.updateProgramImageUrl &&
      typeof props.updateProgramImageUrl == "function"
    ) {
      if (programImage == DEFAULT_PROGRAM_IMAGE_PATH) {
        props.updateProgramImageUrl(null);
      } else {
        props.updateProgramImageUrl(newUrl);
      }
    }
  };

  useEffect(() => {
    if (assignedFailed) {
      setTimeout(() => {
        setAssignedFailed(false);
      }, 3000);
    }
  }, [assignedFailed]);

  useEffect(() => {
    setProgramImage(getProgramImageUrl());
  }, [props.program]);

  useEffect(() => {
    if (programImage === DEFAULT_PROGRAM_IMAGE_PATH) {
      setShowProgramRemoveButton(false);
      onProgramImageChange(null);
    } else {
      setShowProgramRemoveButton(true);
      onProgramImageChange(programImage);
    }
  }, [programImage]);

  function togglePrivacy() {
    axios
      .post(urlTogglePrivacy, { programId: props.program.id })
      .then((response) => {
        // Assuming the response contains a 'isPublic' boolean field to indicate the new privacy state
        if (response.data && response.data.isPublic !== undefined) {
          setIsPublic(response.data.isPublic);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error toggling privacy:", error);
        alert(
          "Ihre Berechtigung ist nicht ausreichend oder ein Fehler ist aufgetreten!"
        );
      });
  }

  const editProgramClick = () => {
    navigate(`/programs/${props.program.id}`, {
      state: { patientName: props.patient ? props.patient : null },
    });
  };

  if (props.state != "t") {
    let status_program = isActive(
      props.program.created_at,
      props.program.duration
    );
    let status;
    let variant;

    if (status_program == true) {
      status = "active";
      variant = "success";
    } else {
      status = "inactive";
      variant = "danger";
    }

    let program_length = props.program.sessions
      ? (props.program.sessions.length /
          (props.program.frequency * props.program.duration)) *
        100
      : 0;

    if (isNaN(program_length)) {
      program_length = 0;
    }
    let sorted_program_sessions = props.program.sessions
      ?.map((obj) => {
        return { ...obj, created_at: new Date(obj.created_at) };
      })
      .sort((a, b) => b.created_at - a.created_at);
    content = (
      <Container>
        <Row className={"program-overview-row-" + status} key={props.id}>
          <Row>
            <Col xs={12} className="mobile-title">
              <h3>
                <b>{props.program.name}</b>
              </h3>
            </Col>
          </Row>
          <Col xl={2} lg={2} md={4} className="workout-thumbnail">
            <center>
              <div style={{ position: "relative", display: "inline-block" }}>
                <label htmlFor={`program-file-input-${props.program.id}`}>
                  <img
                    src={programImage}
                    alt="sophyapp logo"
                    className="program-thumbnail"
                    style={{ cursor: "pointer", maxWidth: "100%" }}
                  />
                </label>
                {showProgramRemoveButton && (
                  <button
                    onClick={() =>
                      handleRemoveImage(
                        getAccessToken,
                        props.program.id,
                        setProgramImage,
                        DEFAULT_PROGRAM_IMAGE_PATH,
                        "program"
                      )
                    }
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      background: "grey",
                      color: "white",
                      border: "none",
                      borderRadius: "50%",
                      cursor: "pointer",
                      width: "15px",
                      height: "15px",
                      textAlign: "center",
                      lineHeight: "15px",
                      fontSize: "14px",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 0,
                    }}
                  >
                    x
                  </button>
                )}
              </div>
            </center>
            <input
              id={`program-file-input-${props.program.id}`}
              type="file"
              accept="image/*"
              onChange={(e) => onImageChange(e, "program_image")}
              style={{ display: "none" }}
            />
          </Col>
          <Col xl={5} lg={5} md={4} className="d-none d-md-block">
            <Row>
              <Col md={12}>
                <h3>
                  <b>{props.program.name}</b>
                </h3>
              </Col>
            </Row>
            <Row></Row>
            <Row md={12}>
              <Col md={12}>
                <div
                  onClick={editProgramClick}
                  style={{ cursor: "pointer", color: "#7c878e" }}
                >
                  {t("program.details")}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} className={"program-progress-" + status}>
                {t("program.program-progress")} {program_length.toFixed(2)} %
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={8} xl={5}>
                <ProgressBar variant={variant} now={program_length} />
              </Col>
            </Row>
          </Col>
          <Col xl={5} lg={5} md={4} className="d-none d-md-block">
            <Row>
              <Col md={12} className={"feedback-title-" + status}>
                {t("feedback.last-feedback")}
              </Col>
            </Row>
            <Row>
              {sorted_program_sessions?.map((session, key) => {
                let feedback_text = session.program_feedback
                  ? session.program_feedback
                  : "";
                if (key > 4) {
                  return;
                }
                return (
                  <Col md={12} className="feedback-details">
                    {new Date(session.created_at).toLocaleDateString("de-DE") +
                      " - (" +
                      session.pain_level +
                      ") " +
                      feedback_text}
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Row className={"mobile-progress-" + status}>
            <Col xs={12}>
              {t("program.program-progress")} {program_length.toFixed(2)} %
            </Col>
          </Row>
          <Row className="mobile-progress-bar">
            <Col xs={12}>
              <ProgressBar variant={variant} now={program_length} />
            </Col>
          </Row>
          <Row className={"mobile-feedback-title-" + status}>
            <Col xs={12}>{t("feedback.last-feedback")}</Col>
          </Row>
          <Row className="mobile-feedback-details">
            {sorted_program_sessions?.map((session, key) => {
              let feedback_text = session.program_feedback
                ? session.program_feedback
                : "";
              if (key > 2) {
                return;
              }
              return (
                <Col xs={12}>
                  {new Date(session.created_at).toLocaleDateString("de-DE") +
                    " - (" +
                    session.pain_level +
                    ") " +
                    feedback_text}
                </Col>
              );
            })}
          </Row>

          <Row className="mobile-details">
            <Col xs={12}>
              <Link
                to={{
                  pathname: `/programs/${props.program.id}`,
                  state: {
                    patientName: props.patient ? props.patient : null,
                  },
                }}
              >
                {t("program.details")}
              </Link>
            </Col>
          </Row>
        </Row>
      </Container>
    );
  } else {
    content = (
      <Container>
        <Row className={"program-overview-row-active"} key={props.id}>
          <Row>
            <Col xs={12} className="mobile-title">
              <h3>
                <b>{props.program.name}</b>
                {props.clientId ? (
                  isPublic ? (
                    <span title={t("programs.public-tooltip")}>
                      <LockOpenIcon
                        onClick={togglePrivacy}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                        fontSize="small"
                        color="success"
                        title={t("programs.public-tooltip")}
                      />
                    </span>
                  ) : (
                    <span title={t("programs.public-tooltip")}>
                      <LockIcon
                        onClick={togglePrivacy}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                        fontSize="small"
                        color="error"
                        title={t("programs.private-tooltip")}
                      />
                    </span>
                  )
                ) : null}
              </h3>
            </Col>
          </Row>
          <Col xl={2} lg={2} md={4} className="workout-thumbnail">
            <label htmlFor={`template-file-input-${props.program.id}`}>
              <center>
                <img
                  src={programImage}
                  alt="sophyapp logo"
                  className="program-thumbnail"
                  style={{ cursor: "pointer" }}
                />
              </center>
              {showProgramRemoveButton && (
                <button
                  onClick={() =>
                    handleRemoveImage(
                      getAccessToken,
                      props.program.id,
                      setProgramImage,
                      DEFAULT_PROGRAM_IMAGE_PATH,
                      "template"
                    )
                  }
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    background: "grey",
                    color: "white",
                    border: "none",
                    borderRadius: "50%",
                    cursor: "pointer",
                    width: "15px",
                    height: "15px",
                    textAlign: "center",
                    lineHeight: "15px",
                    fontSize: "14px",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 0,
                  }}
                >
                  x
                </button>
              )}
            </label>
            <input
              id={`template-file-input-${props.program.id}`}
              type="file"
              accept="image/*"
              onChange={(e) => onImageChange(e, "template_image")}
              style={{ display: "none" }} // Hide the file input
            />
          </Col>
          <Col xl={5} lg={5} md={4} className="d-none d-md-block">
            <Row>
              <Col md={12}>
                <h3>
                  <b>{props.program.name}</b>
                  {props.clientId ? (
                    isPublic ? (
                      <span title={t("programs.public-tooltip")}>
                        <LockOpenIcon
                          onClick={togglePrivacy}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                          fontSize="small"
                          color="success"
                          title={t("programs.public-tooltip")}
                        />
                      </span>
                    ) : (
                      <span title={t("programs.private-tooltip")}>
                        <LockIcon
                          onClick={togglePrivacy}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                          fontSize="small"
                          color="error"
                          title={t("programs.private-tooltip")}
                        />
                      </span>
                    )
                  ) : null}
                </h3>
              </Col>
            </Row>
            <Row></Row>
            <Row md={12} lg={12}>
              <Col md={5} lg={4}>
                <div
                  className={"assign-property-action"}
                  onClick={() =>
                    assignPatient(
                      props.program.id,
                      setModalAssignPatient,
                      props.fetchPatients
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {t("programs.addPatient")} >
                </div>
              </Col>
              <Col md={5} lg={4}>
                <Link to={`/program_template/${props.program.id}`}>
                  {t("program.details")}
                </Link>
              </Col>
            </Row>
          </Col>
          <Row className="mobile-details">
            <Col xs={7}>
              <div
                className={"assign-property-action"}
                onClick={() =>
                  assignPatient(
                    props.program.id,
                    setModalAssignPatient,
                    props.fetchPatients
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {t("programs.addPatient")} >
              </div>
            </Col>
            <Col xs={5}>
              <Link to={`/program_template/${props.program.id}`}>
                {t("programs.detail")} >
              </Link>
            </Col>
          </Row>
        </Row>
        <AssignPatientModal
          show={modalAssignPatient}
          onHide={() => setModalAssignPatient(false)}
          key={props.program.name}
          program={props.program.id}
          patients={props.patients}
          isFetchingPatient={props.isFetchingPatient}
          template={props.state === "t" ? true : false}
          setAssignedFailed={() => setAssignedFailed(true)}
          setAssignedSuccess={() => setAssignedSuccess(true)}
        ></AssignPatientModal>
        <Alert
          variant="success"
          className="alert-fixed"
          show={assignedSuccess}
          onClose={() => setAssignedSuccess(false)}
          dismissible
        >
          <Alert.Heading>{t("alerts.assign-patient-header")}</Alert.Heading>
          <p>{t("alerts.assign-patient-main")}</p>
        </Alert>
        <Alert
          variant="danger"
          className="alert-fixed"
          show={assignedFailed}
          onClose={() => setAssignedFailed(false)}
          dismissible
        >
          <Alert.Heading>
            {t("alerts.assign-patient-header-false")}
          </Alert.Heading>
          <p>{t("alerts.assign-patient-main-false")}</p>
        </Alert>
      </Container>
    );
  }

  return <div style={props.style}>{content}</div>;
}

export default ProgramCard;
