import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
// import { useTranslation } from "react-i18next";
import "./Preferences.css";

export default function Export() {
  // const { t, i18n } = useTranslation();
  return (
    <Container fluid className="about-sop">
      <Row>
        <Col md={10}>Export - coming soon...</Col>
      </Row>
    </Container>
  );
}
