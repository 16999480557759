import react, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Programs.css";
import ProgramCard from "./ProgramCard";
import { getAccessToken } from "../Utils/Utils.js";
import Loader from "../Utils/Loader";
import axios from "axios";
import ProfileCard from "../Profile/ProfileCard";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Popover, MenuItem, Menu } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ProgramTemplateFilters from "../../Comp/Filters/ProgramTemplateFilters";
import updateProgramTemplateImageUrl from "../Services/programUtils.js";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const handleLanguageChange = (lang, lg, name) => {};

function ProgramsOverview() {
  const storedUserData = JSON.parse(localStorage.getItem("userData"));
  const isMobile = useMediaQuery("(max-width:900px)");
  const smallMobile = useMediaQuery("(max-width:500px");
  const [sortParams, setSortParams] = useState({});
  const [content, setContent] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const filterOpen = Boolean(filterAnchorEl);
  const [patients, setPatients] = useState(null);
  const [isFetchingPatients, setIsFetchingPatients] = useState(false);

  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/react/" +
    getAccessToken() +
    "/program/index";

  const [programs, setPrograms] = useState({
    loading: false,
    data: null,
    error: false,
  });
  const [params, setParams] = useState({
    goal: "",
    duration: "",
    q: "",
    own_templates: "",
  });

  const handleOnChangeFilter = async (target, value) => {
    setParams({
      ...params,
      [target]: value,
    });
  };

  const handleFilterIconClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleSortIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSortSelection = (sortOption) => {
    handleOnChangeSort("sort", sortOption);
    handleSortMenuClose();
  };

  const handleOnChangeSort = (name, value) => {
    const newSortParams = { ...sortParams, [name]: value };
    setSortParams(newSortParams);

    // Check if we have program data to sort
    if (programs.data) {
      // Sort the programs with the new sorting parameters
      const sortedData = sortProgramsData(programs.data, newSortParams);
      setPrograms({ ...programs, data: sortedData });
    }
  };

  const sortProgramsData = (data, sortingParams) => {
    if (!data) return [];
    return data
      .map((obj) => ({ ...obj, created_at: new Date(obj.created_at) }))
      .sort((a, b) => {
        switch (sortingParams?.sort) {
          case "name":
            return a.name
              .trim()
              .toLowerCase()
              .localeCompare(b.name.trim().toLowerCase());
          case "date":
            return a.created_at - b.created_at;
          case "duration":
            return a.duration - b.duration;
          default:
            return b.created_at - a.created_at;
        }
      });
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setPrograms({ ...programs, loading: true });
    axios
      .get(url, {
        headers: headers,
        params: {
          q: params.q,
          duration: params.duration,
          goal: params.goal,
          own_templates: params.own_templates,
        },
      })
      .then((response) => {
        const sortedData = sortProgramsData(response.data);
        setPrograms({
          loading: false,
          data: sortedData,
          error: false,
        });
      })
      .catch(() => {
        setPrograms({
          loading: false,
          data: null,
          error: true,
        });
      });
  }, [params]);

  //restructure function and outsource to Api/api.js
  const fetchPatients = async () => {
    setIsFetchingPatients(true);
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_SOPHYAPP_API_URL
        }/de/react/${getAccessToken()}/patient/get_account_patients`
      );
      setPatients(response.data);
    } catch (error) {
      console.error("Error fetching patients:", error);
    } finally {
      setIsFetchingPatients(false);
    }
  };

  useEffect(() => {
    if (programs.loading) {
      setContent(<Loader />);
    } else if (programs.error) {
      setContent(<p>{t("programs.error")}</p>);
    } else if (programs.data) {
      const programElements = programs.data.map((program, key) => (
        <ProgramCard
          program={program}
          id={key}
          key={key}
          clientId={storedUserData.client_id}
          state="t"
          fetchPatients={fetchPatients}
          patients={patients}
          isFetchingPatients={isFetchingPatients}
          style={
            key === programs.data.length - 1 ? { marginBottom: "150px" } : {}
          }
          updateProgramImageUrl={(newUrl) =>
            updateProgramTemplateImageUrl(programs, setPrograms, newUrl, key)
          }
        />
      ));

      setContent(
        <Col md={12} className="program-card-element">
          {programElements}
        </Col>
      );
    }
  }, [programs, patients, isFetchingPatients]);

  var sidebar = (
    <Col lg={3} xxl={2} xxxl={1} className="sidebar-properties h-100">
      <div className="fixed-position">
        <Row>
          <Col md={12} className="sidebar-header">
            {t("programs.programTemplates")}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="">
            <NavLink to="/programs/create" activeclassname="active">
              <button
                type="submit"
                className="btn-new-program"
                style={{ cursor: "pointer" }}
              >
                {window.innerWidth <= 1400
                  ? t("exercises.add")
                  : t("programs.addNewProgram")}
                <img className="plus-icon" src="/images/plus.svg" />
              </button>
            </NavLink>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <input
              type="search"
              name="q"
              className="searchbar-exercises d-none d-sm-block"
              placeholder={t("programs.search")}
              value={setParams.q}
              onChange={(e) =>
                handleOnChangeFilter(e.target.name, e.target.value)
              }
            ></input>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="sidebar-filter">
            <select
              name="sort"
              className="select-box sidebar-filter-elem"
              value={setParams.sort}
              placeholder={t("programs.sortBy")}
              onChange={(e) =>
                handleOnChangeSort(e.target.name, e.target.value)
              }
            >
              <option style={{ display: "none" }} defaultValue>
                {t("programs.sortBy")}
              </option>
              <option value="name">{t("programs.nameFilter")}</option>
              <option value="date">{t("programs.dateFilter")}</option>
              <option value="duration">{t("programs.durationFilter")}</option>
            </select>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="sidebar-exercise-list">
            {t("exercises.filterAlone")}
            <ProgramTemplateFilters params={params} setParams={setParams} />
            {storedUserData.client_id && storedUserData.client_id > 0 ? (
              <Row>
                <Col
                  md={12}
                  className="sidebar-filter"
                  style={{ marginTop: "5px" }}
                >
                  <input
                    type="checkbox"
                    name="own_templates"
                    checked={setParams.own_templates}
                    onChange={(e) =>
                      handleOnChangeFilter(e.target.name, e.target.checked)
                    }
                  />
                  <span> {t("programs.own-templates")}</span>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </div>
    </Col>
  );

  var sidebarMobile = (
    <Row>
      <Col xxs={12} xs={12} className="sidebar-properties-patient-list">
        <Row>
          <Col xxs={12} className="sidebar-header-mobile">
            {t("programs.programTemplates")}
          </Col>
        </Row>
        <Row>
          <Col xxs={9} xs={8} md={6}>
            <input
              type="search"
              name="q"
              className="searchbar-exercises-mobile"
              placeholder={t("programs.search")}
              value={setParams.q}
              onChange={(e) =>
                handleOnChangeFilter(e.target.name, e.target.value)
              }
            ></input>
          </Col>
          <Col
            xxs={1}
            xs={1}
            md={1}
            className="d-flex align-items-center justify-content-center"
          >
            <SortIcon
              onClick={handleSortIconClick}
              style={{ cursor: "pointer" }}
            />
            <Menu anchorEl={anchorEl} open={open} onClose={handleSortMenuClose}>
              <MenuItem onClick={() => handleSortSelection("name")}>
                {t("programs.nameFilter")}
              </MenuItem>
              <MenuItem onClick={() => handleSortSelection("date")}>
                {t("programs.dateFilter")}
              </MenuItem>
              <MenuItem onClick={() => handleSortSelection("duration")}>
                {t("programs.durationFilter")}
              </MenuItem>
            </Menu>
          </Col>
          <Col
            xxs={1}
            xs={1}
            md={1}
            className="d-flex align-items-center justify-content-center"
          >
            <FilterAltIcon
              onClick={handleFilterIconClick}
              style={{ cursor: "pointer" }}
            />
            <Popover
              open={filterOpen}
              anchorEl={filterAnchorEl}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <ProgramTemplateFilters params={params} setParams={setParams} />
            </Popover>
          </Col>
          <Col xxs={1} xs={2} md={4}>
            <center>
              <NavLink to="/programs/create" activeclassname="active">
                <button
                  type="submit"
                  className="btn-new-program-mobile"
                  style={{ cursor: "pointer" }}
                >
                  {smallMobile ? "+" : "Neues Programm hinzufügen"}
                  <img className="plus-icon" src="/images/plus.svg" />
                </button>
              </NavLink>
            </center>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <div className="h-100">
      <Container fluid className="h-100 sidebar-menu container-fluid">
        <Row className={isMobile ? null : "h-100"}>
          {isMobile ? sidebarMobile : sidebar}
          <Col
            xxs={12}
            xs={12}
            md={12}
            lg={9}
            xxl={10}
            xxxl={11}
            className="sb-main-content program-overview-main-mobile"
          >
            <Row>{content}</Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProgramsOverview;
