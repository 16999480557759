import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Utils.css";

export default function BreadCrumbs(props) {
  return (
    <Container fluid>
      <Row className="breadcrumb-space">
        <Col md={12}>{props.value}</Col>
      </Row>
    </Container>
  );
}
