import React, { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";

const SelectField = ({
  options,
  programDetailsValidation,
  handleOnChangeProgramDetails,
  name,
  target,
  labelText,
  labelField,
}) => {
  const [selectVisible, setSelectVisible] = useState(false);
  const selectRef = useRef(null);

  const handleClick = () => {
    setSelectVisible(true);
    if (selectRef.current) {
      selectRef.current.focus();
    }
  };

  const handleSelectBlur = () => {
    setSelectVisible(false);
  };

  return (
    <Row className="program-preview-title">
      <Col md={12} className="sidebar-filter">
        <div
          onClick={handleClick}
          style={{
            display: selectVisible ? "none" : "block",
            cursor: "pointer",
          }}
        >
          {target == ""
            ? labelText
            : labelField + ": " + options[target - 1].label}
        </div>
        <select
          ref={selectRef}
          name={name}
          className={`select-box sidebar-filter-elem ${
            programDetailsValidation[name] == true ? "is-invalid" : ""
          }`}
          onChange={(e) =>
            handleOnChangeProgramDetails(e.target.name, e.target.value)
          }
          onBlur={handleSelectBlur}
          style={{
            display: selectVisible ? "block" : "none",
          }}
        >
          <option style={{ display: "none" }} defaultValue>
            {labelText}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </Col>
    </Row>
  );
};

export default SelectField;
