export const handleTouchStart = (e) => {};
export const handleTouchMove = (e, dragOverItem) => {
  const touch = e.touches[0];
  const touchX = touch.clientX;
  const touchY = touch.clientY;

  const itemElements = document.querySelectorAll(".list-item-wrapper");

  // Iterate over the rectangles and check if the touch is within the bounds of any of them
  for (let i = 0; i < itemElements.length; i++) {
    const element = itemElements[i];
    const rect = element.getBoundingClientRect();
    if (
      touchX >= rect.left &&
      touchX <= rect.right &&
      touchY >= rect.top &&
      touchY <= rect.bottom
    ) {
      // Update the drag over item
      dragOverItem.current = i;
      break;
    }
  }
};

export const editExercisePreview = (index) => {
  const exercisePreviewElement = document.querySelector(
    ".edit-exercise-preview2-" + index
  );
  exercisePreviewElement.style.display =
    exercisePreviewElement.style.display === "none" ? "block" : "none";
};

export const removeExerciseFromList = (
  index,
  exerciseItems,
  setExerciseItems
) => {
  const newItems = [...exerciseItems];
  newItems.splice(index, 1);
  setExerciseItems(newItems);
};

export const updateProperty = (
  index,
  property,
  newValue,
  exerciseItems,
  setExerciseItems
) => {
  const updatedItems = exerciseItems.map((item, i) => {
    if (i === index) {
      return { ...item, [property]: newValue };
    }
    return item;
  });
  setExerciseItems(updatedItems);
};

export const changeExerciseType = (index, exerciseItems, setExerciseItems) => {
  const updatedItems = exerciseItems.map((item, i) => {
    if (i === index) {
      if (item.typeOfExercise === "WH") {
        return { ...item, typeOfExercise: "SEK" };
      } else if (item.typeOfExercise === "SEK") {
        return { ...item, typeOfExercise: "MIN" };
      } else {
        return { ...item, typeOfExercise: "WH" };
      }
    }
    return item;
  });
  setExerciseItems(updatedItems);
};

export function adjustTextareaWidth(textarea) {
  const minWidth = 100; // Minimum width in pixels
  const maxWidth = 200; // Maximum width in pixels
  const charWidth = 8; // Estimated width of a single character in pixels

  const text = textarea.value.replace(/\s/g, ""); // Remove whitespace
  const contentWidth = text.length * charWidth; // Calculate content width based on character count
  const newWidth = Math.min(Math.max(contentWidth, minWidth), maxWidth); // Clamp the width between minWidth and maxWidth

  textarea.style.width = newWidth + "px"; // Apply the new width
}

export const handleSort = (
  dragOverItem,
  dragItem,
  exerciseItems,
  setExerciseItems
) => {
  if (dragOverItem.current == null) {
    return;
  }
  let _items = [...exerciseItems];

  //Remove and save dragged item content
  const draggedItemContent = _items.splice(dragItem.current, 1)[0];

  //switch position
  _items.splice(dragOverItem.current, 0, draggedItemContent);

  //reset exercise items
  dragItem.current = null;
  dragOverItem.current = null;
  setExerciseItems(_items);
};

export function getWidth() {
  return window.innerWidth;
}
