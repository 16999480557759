// ExerciseInputs.js
import React, { useEffect } from "react";
import "../../Assets/stylesheets/ExerciseInputFields.css";
import { useTranslation } from "react-i18next";

const ExerciseInputFields = ({ exercise, handleInputChange, index }) => {
  const { t } = useTranslation();
  return (
    <div className="exercise-inputs">
      <div className="input-group-row">
        {" "}
        <div className="input-group">
          <label htmlFor="type">{t("others.type")}</label>
          <select
            id="typeOfExercise"
            className="exercise-select"
            value={exercise.typeOfExercise || ""}
            onChange={(e) =>
              handleInputChange("typeOfExercise", e.target.value, index)
            }
          >
            <option value="WH">{t("others.wh")}</option>
            <option value="MIN">{t("others.min")}</option>
            <option value="SEK">{t("others.sec")}</option>
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="reps">{t("others.reps")}</label>
          <input
            type="number"
            value={exercise.reps || ""}
            placeholder="Reps"
            onChange={(e) => handleInputChange("reps", e.target.value, index)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="set">{t("others.sets")}</label>
          <input
            type="number"
            value={exercise.set || ""}
            placeholder="Set"
            onChange={(e) => handleInputChange("set", e.target.value, index)}
          />
        </div>
      </div>
      <div className="input-group-row">
        {" "}
        <div className="input-group">
          <label htmlFor="break">{t("others.break")}</label>
          <input
            type="number"
            value={exercise.break || ""}
            placeholder="Break"
            onChange={(e) => handleInputChange("break", e.target.value, index)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="weight">{t("others.weight")}</label>
          <input
            type="number"
            value={exercise.weight || ""}
            placeholder="Weight"
            onChange={(e) => handleInputChange("weight", e.target.value, index)}
          />
        </div>
      </div>
      <div className="input-group">
        <label htmlFor="note">Note</label>
        <input
          type="text"
          value={exercise.note || ""}
          placeholder="Note"
          onChange={(e) => handleInputChange("note", e.target.value, index)}
        />
      </div>
    </div>
  );
};

export default ExerciseInputFields;
