import react, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Programs.css";
import ProgramCard from "./ProgramCard";
import { getAccessToken } from "../Utils/Utils.js";
import Loader from "../Utils/Loader";
import axios from "axios";
import ProfileCard from "../Profile/ProfileCard";
import { useTranslation } from "react-i18next";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

function Programs(props) {
  const { t, i18n } = useTranslation();
  let content = null;
  let sorted_programs =
    props.program?.programs ||
    []

      ?.map((obj) => {
        return { ...obj, created_at: new Date(obj.created_at) };
      })
      .sort((a, b) => b.created_at - a.created_at);
  content = (
    <Container fluid className="program-overview-container">
      {sorted_programs && sorted_programs.length === 0 ? (
        <p>{t("program.no-program-created")}</p>
      ) : (
        sorted_programs.map((program, key) => (
          <ProgramCard
            program={program}
            id={key}
            key={key}
            state="p"
            patient={props.program.name}
            updateProgramImageUrl={(newUrl) =>
              props.updateProgramImageUrl(newUrl, key)
            }
          />
        ))
      )}
    </Container>
  );

  return <div>{content}</div>;
}

export default Programs;
