import React, { useState, useEffect } from "react";
import { Button, TextField, Checkbox, Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getAccessToken } from "../Utils/Utils.js";
import axios from "axios";
import { Helmet } from "react-helmet";

function PaymentMethod() {
  const [paymentMethod, setPaymentMethod] = useState("invoice");
  const [therapists, setTherapists] = useState(1);
  const [iban, setIban] = useState("");
  const [bankName, setBankName] = useState("");
  const [order, setOrder] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [paymentMade, setPaymentMade] = useState(false);

  useEffect(() => {
    if (iban && bankName && order) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [iban, bankName, order]);

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const handleTherapistChange = (e) => {
    setTherapists(e.target.value);
    setPaymentMade(false);
  };

  const handlePayment = () => {
    window.open("https://sophyapp.onfastspring.com/sop" + therapists, "_blank");
    setPaymentMade(true);
  };

  const handleConfirm = () => {
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      `/de/react/${getAccessToken()}/settings/update_payment`;
    var params = new FormData();
    params.append("iban", iban);
    params.append("bankName", bankName);
    params.append("paymentMethod", paymentMethod);
    params.append("therapists", therapists);
    axios
      .post(url, params)
      .then(function (response) {
        alert("Abo erfolgreich geändert - vielen Dank!");
        window.location.reload();
      })
      .catch(function (error) {
        alert("Es ist ein Fehler aufgetreten: " + error.message);
      });
  };

  return (
    <Box sx={{ p: 2 }}>
      <h1>Zahlungsmethode</h1>
      <Box sx={{ display: "flex", gap: 2, my: 2 }}>
        <Button
          variant="contained"
          style={{
            backgroundColor:
              paymentMethod === "invoice" ? "var(--main-color)" : "lightgrey",
          }}
          onClick={() => handlePaymentMethodChange("invoice")}
        >
          Invoice
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor:
              paymentMethod === "credit" ? "var(--main-color)" : "lightgrey",
          }}
          onClick={() => handlePaymentMethodChange("credit")}
        >
          Credit Card
        </Button>
      </Box>

      {paymentMethod === "credit" ? (
        <>
          <Box component="div" m={1}>
            <TextField
              id="outlined-basic"
              type="number"
              label="Therapeuten"
              variant="outlined"
              value={therapists}
              onChange={handleTherapistChange}
              inputProps={{ min: 1, max: 10 }}
            />
          </Box>
          <Box component="div" m={1}>
            {!paymentMade ? (
              <Button
                variant="contained"
                style={{ backgroundColor: "var(--main-color)" }}
                onClick={handlePayment}
              >
                Zur Zahlung
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{ backgroundColor: "var(--main-color)" }}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            )}
          </Box>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Box sx={{ my: 1 }}>
            <TextField
              type="number"
              value={therapists}
              onChange={handleTherapistChange}
              label="Therapists"
              inputProps={{ min: 1, max: 10 }}
            />
          </Box>
          <p>
            <b>Gesamt:</b>
            <i> {therapists * 13.0} Euro exkl. Mwst pro Monat</i>
          </p>
          <Box sx={{ my: 1 }}>
            <TextField
              type="text"
              value={iban}
              onChange={(e) => setIban(e.target.value)}
              label="IBAN"
            />
          </Box>
          <Box sx={{ my: 1 }}>
            <TextField
              type="text"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
              label="Name der Bank"
            />
          </Box>
          <Box sx={{ my: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={order}
                  onChange={(e) => setOrder(e.target.checked)}
                />
              }
              label="kostenpflichtig bestellen"
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            disabled={!isFormValid}
            style={{
              backgroundColor: isFormValid ? "var(--main-color)" : "lightgrey",
            }}
          >
            Submit
          </Button>
        </form>
      )}
    </Box>
  );
}

export default PaymentMethod;
