import React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import SettingsIcon from "@mui/icons-material/Settings";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NavLink } from "react-router-dom";

export default function MobileMenu() {
  const [value, setValue] = React.useState(0);
  const isMobile = useMediaQuery("(max-width:900px)");

  if (!isMobile) {
    return null;
  }

  return (
    <Box sx={{ width: "100%", position: "fixed", bottom: 0, height: "56px" }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          "& .Mui-selected, .Mui-selected > svg": {
            color: "var(--main-color)",
          },
        }}
      >
        <BottomNavigationAction
          icon={<PeopleAltIcon />}
          component={NavLink}
          to="/patients"
          value="/patients"
        />

        <BottomNavigationAction
          icon={<FormatListNumberedIcon />}
          component={NavLink}
          to="/programs"
          value="/programs"
        />
        {/* -- currently not in use -- */}
        {/* <BottomNavigationAction
          icon={<GridViewIcon />}
          component={NavLink}
          to="/dashboard"
          value="/dashboard"
        /> */}
        <BottomNavigationAction
          icon={<SportsGymnasticsIcon />}
          component={NavLink}
          to="/exercises"
          value="/exercises"
        />
        <BottomNavigationAction
          icon={<SettingsIcon />}
          component={NavLink}
          to="/preferences/profile"
          value="/preferences/profile"
        />
      </BottomNavigation>
    </Box>
  );
}
