import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import "./Preferences.css";
import axios from "axios";
import { getAccessToken } from "../Utils/Utils.js";
import Loader from "../Utils/Loader";
import Alert from "react-bootstrap/Alert";

export default function Generals() {
  const storedUserData = JSON.parse(localStorage.getItem("userData"));
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/general`;
  const updateUrl =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/update_general`;
  const locationUrl =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/get_locations`;
  const headers = {
    "Content-Type": "multipart-form-data",
    Accept: "application/json",
  };

  const [request, setRequest] = useState({
    loading: false,
    data: null,
    error: false,
  });
  const [settings, setSettings] = useState({
    ex_weight: "",
    weight_unit: "",
    print_note: "",
    font_size: "",
    exercises_per_row: "",
    zopim_set: "",
    font_size_notes: "",
    patient_id: "",
    font_bold: "",
    image_size: "",
    show_client_switch: "",
    print_linear: "",
    pre_sexuality: "",
    set_3cx: "",
    show_all_exercises: "",
    patient_sort_order: "",
    prefer_location: "",
    location: "",
    print_patient_name: "",
    get_feedback_notification: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState();
  const [alertHeader, setAlertHeader] = useState();
  const [alertBody, setAlertBody] = useState();
  const [locationOptions, setLocationOptions] = useState([]);
  const { t } = useTranslation();

  const handleOnChange = async (target, value) => {
    setSettings({
      ...settings,
      [target]: value,
    });
  };

  const handleSubmit = (e) => {
    // Update the ex_weight attribute in the storedUserdata object
    storedUserData.setting["ex_weight"] = settings.ex_weight;
    storedUserData.setting["patient_sort_order"] = settings.patient_sort_order;
    // Save the updated userdata back to localStorage
    localStorage.setItem("userData", JSON.stringify(storedUserData));

    var params = new FormData();

    params.append("settings[ex_weight]", settings.ex_weight);
    params.append("settings[weight_unit]", settings.weight_unit);
    params.append("settings[print_note]", settings.print_note);
    params.append("settings[font_size]", settings.font_size);
    params.append("settings[exercises_per_row]", settings.exercises_per_row);
    params.append("settings[zopim_set]", settings.zopim_set);
    params.append("settings[font_size_notes]", settings.font_size_notes);
    // params.append("settings[patient_id]", settings.patient_id);
    params.append("settings[font_bold]", settings.font_bold);
    params.append("settings[image_size]", settings.image_size);
    params.append("settings[show_client_switch]", settings.show_client_switch);
    params.append("settings[print_linear]", settings.print_linear);
    params.append("settings[pre_sexuality]", settings.pre_sexuality);
    params.append("settings[set_3cx]", settings.set_3cx);
    params.append("settings[show_all_exercises]", settings.show_all_exercises);
    params.append("settings[patient_sort_order]", settings.patient_sort_order);
    params.append("settings[prefer_location]", settings.prefer_location);
    params.append("settings[print_patient_name]", settings.print_patient_name);
    params.append(
      "settings[get_feedback_notification]",
      settings.get_feedback_notification
    );
    params.append("location", settings.location);
    axios
      .put(updateUrl + ".json", params)
      .then(function (response) {
        setAlertVariant("success");
        setAlertHeader("Erfolgreich gespeichert");
        setAlertBody("Daten wurden erfolgreich gespeichert");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .catch(function (error) {
        setAlertVariant("danger");
        setAlertHeader("Nicht gespeichert");
        setAlertBody("Daten konnten nicht gespeichert werden");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      });
    e.preventDefault();
  };

  let content = null;

  useEffect(() => {
    setRequest({
      loading: true,
      data: null,
      error: false,
    });
    axios
      .get(url, { headers: headers })
      .then((response) => {
        setRequest({
          loading: false,
          data: response.data,
          error: false,
        });
        setSettings({
          ex_weight: response.data.settings.ex_weight,
          weight_unit: response.data.settings.weight_unit,
          print_note: response.data.settings.print_note,
          font_size: response.data.settings.font_size,
          exercises_per_row: response.data.settings.exercises_per_row,
          zopim_set: response.data.settings.zopim_set,
          font_size_notes: response.data.settings.font_size_notes,
          patient_id: response.data.settings.patient_id,
          font_bold: response.data.settings.font_bold,
          image_size: response.data.settings.image_size,
          show_client_switch: response.data.settings.show_client_switch,
          print_linear: response.data.settings.print_linear,
          pre_sexuality: response.data.settings.pre_sexuality,
          set_3cx: response.data.settings.set_3cx,
          show_all_exercises: response.data.settings.show_all_exercises,
          patient_sort_order: response.data.settings.patient_sort_order,
          prefer_location: response.data.settings.prefer_location,
          print_patient_name: response.data.settings.print_patient_name,
          get_feedback_notification:
            response.data.settings.get_feedback_notification,
          location: response.data.location,
        });
      })
      .catch(() => {
        setRequest({
          loading: false,
          data: null,
          error: true,
        });
      });
    if (storedUserData.client_id && storedUserData.client_id > 0) {
      axios
        .get(locationUrl)
        .then((response) => {
          setLocationOptions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching locations:", error);
        });
    }
  }, [url]);

  if (request.error) {
    content = <div>{t("general.patientsFailed")}</div>;
  }

  if (request.loading) {
    content = <Loader />;
  }

  if (request.data) {
    content = (
      <Container fluid className="h-100 wrapper-mobile">
        <Row className="h-100 table-row general-settings">
          <div className="general-settings-header">
            {t("general.exerciseSettings")}
          </div>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("general.exerciseWeight")}
            </Col>
            <Col md={5}>
              <input
                name="ex_weight"
                type="checkbox"
                checked={settings.ex_weight}
                className="profile-input-field"
                onChange={(e) =>
                  handleOnChange(e.target.name, e.target.checked)
                }
              />
            </Col>
          </Row>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("general.exerciseWeightUnit")}
            </Col>
            <Col md={5}>
              <select
                type="text"
                name="weight_unit"
                className="profile-input-field"
                value={settings.weight_unit}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              >
                <option value="kg">kg</option>
                <option value="lbs">lbs</option>
              </select>
            </Col>
          </Row>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("general.gender")}
            </Col>
            <Col md={5}>
              <input
                type="checkbox"
                name="pre_sexuality"
                className="profile-input-field"
                checked={settings.pre_sexuality}
                onChange={(e) =>
                  handleOnChange(e.target.name, e.target.checked)
                }
              />
            </Col>
          </Row>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("general.whichExercise")}
            </Col>
            <Col md={5}>
              <select
                type="text"
                name="show_all_exercises"
                className="profile-input-field"
                value={settings.show_all_exercises}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              >
                <option value="1">{t("profile.all")}</option>
                <option value="0">{t("profile.own")}</option>
              </select>
            </Col>
          </Row>
        </Row>
        <Row className="h-100 table-row general-settings">
          <div className="general-settings-header">
            {t("general.printSettings")}
          </div>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("settings.linearPrint")}
            </Col>
            <Col md={5}>
              <input
                name="print_linear"
                type="checkbox"
                checked={settings.print_linear}
                className="profile-input-field"
                onChange={(e) =>
                  handleOnChange(e.target.name, e.target.checked)
                }
              />
            </Col>
          </Row>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("settings.printNote")}
            </Col>
            <Col md={5}>
              <input
                type="checkbox"
                name="print_note"
                className="profile-input-field"
                checked={settings.print_note}
                onChange={(e) =>
                  handleOnChange(e.target.name, e.target.checked)
                }
              />
            </Col>
          </Row>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("settings.imageSize")}
            </Col>
            <Col md={5}>
              <select
                type="text"
                name="image_size"
                className="profile-input-field"
                value={settings.image_size}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              >
                <option value="0">{t("settings.default")}</option>
                <option value="200px">200px</option>
                <option value="300px">300px</option>
              </select>
            </Col>
          </Row>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("general.fontSizeDescription")}
            </Col>
            <Col md={5}>
              <select
                type="text"
                name="font_size"
                className="profile-input-field"
                value={settings.font_size}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              >
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10"> 10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13"> 13</option>
                <option value="14"> 14</option>
                <option value="15"> 15</option>
                <option value="16"> 16</option>
              </select>
            </Col>
          </Row>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("general.exercisesPerRow")}
            </Col>
            <Col md={5}>
              <select
                type="text"
                name="exercises_per_row"
                className="profile-input-field"
                value={settings.exercises_per_row}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              >
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </Col>
          </Row>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("general.fontSizeNotes")}
            </Col>
            <Col md={5}>
              <select
                type="text"
                name="font_size_notes"
                className="profile-input-field"
                value={settings.font_size_notes}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              >
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10"> 10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13"> 13</option>
              </select>
            </Col>
          </Row>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("general.fontBold")}
            </Col>
            <Col md={5}>
              <input
                type="checkbox"
                name="font_bold"
                className="profile-input-field"
                checked={settings.font_bold}
                onChange={(e) =>
                  handleOnChange(e.target.name, e.target.checked)
                }
              />
            </Col>
          </Row>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("general.printPatientName")}
            </Col>
            <Col md={5}>
              <input
                type="checkbox"
                name="print_patient_name"
                className="profile-input-field"
                checked={settings.print_patient_name}
                onChange={(e) =>
                  handleOnChange(e.target.name, e.target.checked)
                }
              />
            </Col>
          </Row>
        </Row>
        <Row className="h-100 table-row general-settings">
          <div className="general-settings-header">{t("general.others")}</div>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("general.patientSortOrder")}
            </Col>
            <Col md={5}>
              <select
                type="text"
                name="patient_sort_order"
                className="profile-input-field"
                value={settings.patient_sort_order}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              >
                <option value="">{t("settings.default")}</option>
                <option value="v">{t("settings.firstnameFirst")}</option>
                <option value="n">{t("settings.lastnameFirst")}</option>
              </select>
            </Col>
          </Row>
          <Row className="h-100 table-row">
            <Col md={3} className="table-column-title">
              {t("settings.patientFeedback")}
            </Col>
            <Col md={5}>
              <select
                type="text"
                name="get_feedback_notification"
                className="profile-input-field"
                value={settings.get_feedback_notification}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              >
                <option value="true">
                  {t("settings.setFeedbackNotification")}
                </option>
                <option value="false">
                  {t("settings.unsetFeedbackNotification")}
                </option>
              </select>
            </Col>
          </Row>
          {storedUserData.client_id && storedUserData.client_id > 0 ? (
            <>
              <Row className="h-100 table-row">
                <Col md={3} className="table-column-title">
                  {t("general.location")}
                </Col>
                <Col md={5}>
                  <select
                    type="text"
                    name="location"
                    className="profile-input-field"
                    value={settings.location}
                    onChange={(e) =>
                      handleOnChange(e.target.name, e.target.value)
                    }
                  >
                    <option value="">{t("general.noLocationSet")}</option>
                    {locationOptions?.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Row className="h-100 table-row">
                <Col md={3} className="table-column-title">
                  {t("general.locationPreference")}
                </Col>
                <Col md={5}>
                  <input
                    type="checkbox"
                    name="prefer_location"
                    className="profile-input-field"
                    checked={settings.prefer_location}
                    onChange={(e) =>
                      handleOnChange(e.target.name, e.target.checked)
                    }
                  />
                </Col>
              </Row>
            </>
          ) : null}
        </Row>
        <Row className="h-100 table-row">
          <Col md={3} className="table-column-title">
            <button
              type="submit"
              className="login-button"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {t("profile.save")}
            </button>
          </Col>
        </Row>
        <Alert
          variant={alertVariant}
          show={showAlert}
          className="alert-fixed"
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <Alert.Heading>{alertHeader}</Alert.Heading>
          <p>{alertBody}</p>
        </Alert>
      </Container>
    );
  }

  return <React.Fragment>{content}</React.Fragment>;
}
