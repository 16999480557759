const updateProgramTemplateImageUrl = (
  programs,
  setPrograms,
  newUrl,
  programIndex
) => {
  const updatedPrograms = [...programs.data];

  if (!updatedPrograms[programIndex]) {
    //console.error("Program not found at index:", programIndex);
    return;
  }

  updatedPrograms[programIndex].program_image_url = newUrl;

  setPrograms({ ...programs, data: updatedPrograms });
};

export default updateProgramTemplateImageUrl;
