export const goalOptions = (t) => [
  { value: "1", label: t("options.goal.schmerzlinderung") },
  { value: "2", label: t("options.goal.kraftigung") },
  { value: "3", label: t("options.goal.beweglichkeit") },
  { value: "4", label: t("options.goal.koordination") },
  { value: "5", label: t("options.goal.pravention") },
  { value: "6", label: t("options.goal.entspannung") },
  { value: "7", label: t("options.goal.ausdauer") },
];

export const frequencyOptions = (t) => [
  { value: "1", label: t("options.frequency.1x") },
  { value: "2", label: t("options.frequency.2x") },
  { value: "3", label: t("options.frequency.3x") },
  { value: "4", label: t("options.frequency.4x") },
  { value: "5", label: t("options.frequency.5x") },
  { value: "6", label: t("options.frequency.6x") },
  { value: "7", label: t("options.frequency.7x") },
];

export const durationOptions = (t) => [
  { value: "1", label: t("options.duration.1") },
  { value: "2", label: t("options.duration.2") },
  { value: "3", label: t("options.duration.3") },
  { value: "4", label: t("options.duration.4") },
  { value: "5", label: t("options.duration.5") },
  { value: "6", label: t("options.duration.6") },
  { value: "7", label: t("options.duration.7") },
  { value: "8", label: t("options.duration.8") },
];

export const bodyRegionOptions = (t) => [
  { value: "all", label: t("options.bodyRegion.all") },
  { value: "CORE", label: t("options.bodyRegion.CORE") },
  { value: "cervical-spine", label: t("options.bodyRegion.cervicalSpine") },
  { value: "thoracic-spine", label: t("options.bodyRegion.thoracicSpine") },
  { value: "lumbar-spine", label: t("options.bodyRegion.lumbarSpine") },
  { value: "UPPER-LIMB", label: t("options.bodyRegion.upperLimb") },
  { value: "shoulder", label: t("options.bodyRegion.shoulder") },
  { value: "elbow", label: t("options.bodyRegion.elbow") },
  { value: "hand", label: t("options.bodyRegion.hand") },
  { value: "LOWER-LIMB", label: t("options.bodyRegion.lowerLimb") },
  { value: "hip", label: t("options.bodyRegion.hip") },
  { value: "knee", label: t("options.bodyRegion.knee") },
  { value: "foot", label: t("options.bodyRegion.foot") },
  { value: "legs", label: t("options.bodyRegion.legs") },
  { value: "arms", label: t("options.bodyRegion.arms") },
  { value: "chest", label: t("options.bodyRegion.chest") },
  { value: "body", label: t("options.bodyRegion.body") },
  { value: "abductor", label: t("options.bodyRegion.abductor") },
  { value: "adductor", label: t("options.bodyRegion.adductor") },
  { value: "back", label: t("options.bodyRegion.back") },
  { value: "biceps", label: t("options.bodyRegion.biceps") },
  { value: "triceps", label: t("options.bodyRegion.triceps") },
  { value: "lungs", label: t("options.bodyRegion.lungs") },
];

export const positionOptions = (t) => [
  { value: "all", label: t("options.position.all") },
  { value: "lying", label: t("options.position.lying") },
  { value: "sitting", label: t("options.position.sitting") },
  { value: "all-fours", label: t("options.position.allFours") },
  { value: "standing", label: t("options.position.standing") },
  { value: "knee", label: t("options.position.knee") },
];

export const goalFilterOptions = (t) => [
  { value: "all", label: t("options.goal.all") },
  {
    value: "stabilisation/strength",
    label: t("options.goal.stabilisationStrength"),
  },
  { value: "mobilisation", label: t("options.goal.mobilisation") },
  { value: "coordination", label: t("options.goal.coordination") },
  { value: "stretch", label: t("options.goal.stretch") },
  { value: "endurance", label: t("options.goal.endurance") },
  { value: "neurodynamic", label: t("options.goal.neurodynamic") },
];

export const difficultyOptions = (t) => [
  { value: "all", label: t("options.difficulty.all") },
  { value: "easy", label: t("options.difficulty.easy") },
  { value: "medium", label: t("options.difficulty.medium") },
  { value: "hard", label: t("options.difficulty.hard") },
];

export const toolOptions = (t) => [
  { value: "all", label: t("options.tool.all") },
  { value: "kettle", label: t("options.tool.kettle") },
  { value: "blackroll", label: t("options.tool.blackroll") },
  { value: "theraband", label: t("options.tool.theraband") },
  { value: "dumbbell", label: t("options.tool.dumbbell") },
  { value: "barbell", label: t("options.tool.barbell") },
  { value: "pezziball", label: t("options.tool.pezziball") },
  { value: "others", label: t("options.tool.others") },
  { value: "none", label: t("options.tool.none") },
];

export const providerOptions = (t) => [
  { value: "all", label: t("options.provider.all") },
  { value: "sophyapp", label: t("options.provider.sophyapp") },
  { value: "cornell", label: t("options.provider.cornell") },
  { value: "precision", label: t("options.provider.precision") },
  { value: "physio10", label: t("options.provider.physio10") },
];
