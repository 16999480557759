import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getAccessToken } from "../Utils/Utils.js";
import axios from "axios";
import Loader from "../Utils/Loader";
import "./Preferences.css";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import Alert from "react-bootstrap/Alert";

function checkPasswordPolicy(pw) {
  if (pw.length > 6) {
    return true;
  } else {
    return false;
  }
}

export default function Preferences() {
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/profile`;
  const update_url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/update_profile`;
  const headers = {
    "Content-Type": "multipart-form-data",
    Accept: "application/json",
  };

  const [request, setRequest] = useState({
    loading: false,
    data: null,
    error: false,
  });
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [id, setId] = useState();
  const [clinicname, setClinicname] = useState();
  const [firstname, setFirstname] = useState();
  const [zip, setZip] = useState();
  const [street, setStreet] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [phone, setPhone] = useState();
  const [website, setWebsite] = useState();
  const [image, setImage] = useState();
  const [job, setJob] = useState();
  const [map, setMap] = useState();
  const [recommend, setRecommend] = useState();
  const [lastname, setLastname] = useState();
  const [language, setLanguage] = useState();
  const [languageAbb, setLanguageAbb] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState();
  const [alertHeader, setAlertHeader] = useState();
  const [alertBody, setAlertBody] = useState();
  // const [account, setAccount] = useState({
  //   id: "",
  //   clinic_name: "",
  //   street: "",
  //   zip: "",
  //   city: "",
  //   country: "",
  //   first_name: "",
  //   last_name: "",
  //   phone: ""
  // });
  const { t, i18n } = useTranslation();

  // toDO make abbreviations/language handling much simpler
  const handleLanguageChange = (lang, lg, name) => {
    let abbrev;

    if (lang == "English") {
      abbrev = "en";
      setLanguage("English");
    } else if (lang == "Italian") {
      abbrev = "it";
      setLanguage("Italian");
    } else {
      abbrev = "de";
      setLanguage("Deutsch");
    }
    i18n.changeLanguage(abbrev);
    setLanguageAbb(abbrev);
  };

  const handleSubmit = (e) => {
    var params = new FormData();
    params.append("account[clinic_name]", clinicname);
    params.append("account[first_name]", firstname);
    params.append("account[last_name]", lastname);
    params.append("account[zip]", zip);
    params.append("account[street]", street);
    params.append("account[phone]", phone);
    params.append("account[country]", country);
    params.append("account[website]", website);
    params.append("account[language]", languageAbb);
    params.append("account[job]", job);
    params.append("account[image]", image);
    params.append("account[map]", map);
    params.append("account[recommend]", recommend);
    params.append("account[language]", languageAbb);
    if (password != null) {
      if (checkPasswordPolicy(password)) {
        params.append("password", password);
        params.append("password_confirmation", password);
      }
    }
    axios
      .put(update_url + ".json", params)
      .then(function (response) {
        setAlertVariant("success");
        setAlertHeader("Erfolgreich gespeichert");
        setAlertBody("Daten wurden erfolgreich gespeichert");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .catch(function (error) {
        setAlertVariant("danger");
        setAlertHeader("Nicht gespeichert");
        setAlertBody("Daten konnten nicht gespeichert werden");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      });
    e.preventDefault();
  };

  let patient = null;
  let content = null;

  useEffect(() => {
    setRequest({
      loading: true,
      data: null,
      error: false,
    });
    axios
      .get(url, { headers: headers })
      .then((response) => {
        setRequest({
          loading: false,
          data: response.data,
          error: false,
        });
        setId(response.data.id);
        setClinicname(response.data.clinic_name);
        setStreet(response.data.street);
        setZip(response.data.zip);
        setCountry(response.data.country);
        setPhone(response.data.phone);
        setWebsite(response.data.website);
        setJob(response.data.job);
        setFirstname(response.data.first_name);
        setLastname(response.data.last_name);
        setEmail(response.data.email);
        setMap(response.data.map == "false" ? false : true);
        setRecommend(response.data.recommend);
        setImage(response.data.image);

        if (response.data.language == "en") {
          setLanguage("English");
          setLanguageAbb("en");
        } else if (response.data.language == "it") {
          setLanguage("Italian");
          setLanguageAbb("it");
        } else {
          setLanguage("Deutsch");
          setLanguageAbb("de");
        }
      })
      .catch(() => {
        setRequest({
          loading: false,
          data: null,
          error: true,
        });
      });
  }, [url]);

  if (request.error) {
    content = <div>{t("others.dataNotFound")}</div>;
  }

  if (request.loading) {
    content = <Loader />;
  }

  if (request.data) {
    content = (
      <Container fluid className="h-100 wrapper-mobile">
        <Row className="h-100">
          <Col md={10}>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("profile.logoUpload")}
              </Col>
              <Col md={5}>
                <input
                  type="file"
                  name="logo"
                  accept="image/*"
                  className="profile-input-field"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("profile.clinicName")}
              </Col>
              <Col md={5}>
                <input
                  type="text"
                  name="clinicname"
                  className="profile-input-field"
                  value={clinicname}
                  onChange={(e) => setClinicname(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("profile.accountId")}
              </Col>
              <Col md={5}>
                <input
                  type="text"
                  name="id"
                  className="profile-input-field"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("profile.street")}
              </Col>
              <Col md={5}>
                <input
                  type="text"
                  name="street"
                  className="profile-input-field"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("profile.zip")}
              </Col>
              <Col md={5}>
                <input
                  type="text"
                  name="zip"
                  className="profile-input-field"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("profile.country")}
              </Col>
              <Col md={5}>
                <select
                  type="text"
                  name="country"
                  className="profile-input-field"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option name="de" value="Österreich">
                    Österreich
                  </option>
                  <option value="Deutschland">Deutschland</option>
                  <option value="Italien"> Italien</option>
                  <option value="Schweiz"> Schweiz</option>
                  <option value="Frankreich"> Frankreich</option>
                  <option value="Niederlande"> Niederlande</option>
                  <option value="Ungarn"> Ungarn</option>
                  <option value="Sonstige"> Nicht aufgelistet</option>
                </select>
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("profile.firstname")}
              </Col>
              <Col md={5}>
                <input
                  type="text"
                  name="name"
                  className="profile-input-field"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("profile.lastname")}
              </Col>
              <Col md={5}>
                <input
                  type="text"
                  name="lastname"
                  className="profile-input-field"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("profile.phone")}
              </Col>
              <Col md={5}>
                <input
                  type="text"
                  name="phone"
                  className="profile-input-field"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("profile.website")}
              </Col>
              <Col md={5}>
                <input
                  type="text"
                  name="website"
                  className="profile-input-field"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("profile.email")}
              </Col>
              <Col md={5}>
                <input
                  type="text"
                  name="email"
                  className="profile-input-field"
                  readOnly
                  value={email}
                />
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("password.change")}
              </Col>
              <Col md={5}>
                <input
                  type="text"
                  name="password"
                  className="profile-input-field"
                  type="password"
                  value={password}
                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("profile.job")}
              </Col>
              <Col md={5}>
                <input
                  type="text"
                  name="job"
                  className="profile-input-field"
                  value={job}
                  onChange={(e) => setJob(e.target.value)}
                />
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("language")}
              </Col>
              <Col md={5}>
                {/* <input
              type="text"
              name="language"
              className="profile-input-field"
              value="Deutsch"
              readOnly
            /> */}
                <select
                  name="language"
                  value={language}
                  onChange={(event) =>
                    handleLanguageChange(
                      event.target.value,
                      event.target.id,
                      event.target.name
                    )
                  }
                  className="select-box"
                >
                  <option id="de" name="de">
                    German
                  </option>
                  <option id="en">English</option>
                  <option id="it"> Italian</option>
                </select>
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("profile.map")}
              </Col>
              <Col md={5}>
                <input
                  type="checkbox"
                  checked={map}
                  name="name"
                  className="profile-input-field"
                  onChange={(e) => {
                    setMap(e.target.checked);
                  }}
                />
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                {t("profile.recommend")}
              </Col>
              <Col md={5}>
                <input
                  type="checkbox"
                  name="recommend"
                  className="profile-input-field"
                  checked={recommend}
                  onChange={(e) => {
                    setRecommend(e.target.checked);
                  }}
                />
              </Col>
            </Row>
            <Row className=" table-row">
              <Col md={3} className="table-column-title">
                <button
                  type="submit"
                  className="login-button"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {t("profile.save")}
                </button>
              </Col>
            </Row>
            <Alert
              variant={alertVariant}
              show={showAlert}
              className="alert-fixed"
              onClose={() => setShowAlert(false)}
              dismissible
            >
              <Alert.Heading>{alertHeader}</Alert.Heading>
              <p>{alertBody}</p>
            </Alert>
          </Col>
          <Col md={2}>
            {image && <img className="previewLogo" src={image.url} />}
          </Col>
        </Row>
      </Container>
    );
  }

  return <React.Fragment>{content}</React.Fragment>;
}
