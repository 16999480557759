import React, { useState } from "react";
import Draggable from "react-draggable";
import { Grid, IconButton } from "@mui/material";
import "../../Assets/stylesheets/DraggableCircle.css";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import ExercisePreviewModal from "../Modals/ExercisePreviewModal";

const DraggableCircle = ({ exercises, setExercises }) => {
  const [open, setOpen] = useState(false);
  const [gridSize, setGridSize] = useState(1);
  const [dragged, setDragged] = useState(false);

  const toggleGridSize = () => {
    setGridSize((prevSize) => (prevSize === 3 ? 1 : prevSize + 1));
  };

  const onStart = () => {
    setDragged(false);
  };

  const onDrag = (e, data) => {
    if (Math.abs(data.deltaX) > 0 || Math.abs(data.deltaY) > 0) {
      setDragged(true);
    }
  };

  const onStop = (event) => {
    if (!dragged) {
      setOpen(!open);
    }
    setDragged(false);
  };

  const handleTouchEnd = (event) => {
    if (!dragged) {
      setOpen(!open);
    }
  };

  return (
    <>
      <Draggable onStart={onStart} onDrag={onDrag} onStop={onStop}>
        <div
          className="draggable-circle"
          onMouseDown={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
          onTouchEnd={handleTouchEnd}
        >
          <IconButton>
            <SportsGymnasticsIcon className="white-icon-button" />
          </IconButton>
        </div>
      </Draggable>
      <ExercisePreviewModal
        show={open}
        handleClose={() => setOpen(false)}
        gridSize={gridSize}
        toggleGridSize={toggleGridSize}
        exercises={exercises}
        setExercises={setExercises}
      />
    </>
  );
};

export default DraggableCircle;
